@import "assets/scss/style.scss";
@import "assets/scss/custome.css";

@import "assets/scss/kendoDefaultTheme.css";
@import "assets/scss/partials/layouts/dark.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600&family=Source+Serif+Pro:wght@300;400;600&family=Vollkorn&display=swap");
@import "assets/scss/style1.scss";

body {
  --collier-color: #25408f;
  --jll-color: #e30613;
  --pepsico-color: #004c97;
  --cushman-color: #e4002b;
}

.nav-outside.mob-backdrop {
  &.mob-fixed .pcoded-navbar {
    z-index: 1032;
  }

  ~ .pcoded-outside {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1031;
      background: rgba(0, 0, 0, 0.25);
    }
  }
}

.pcoded-header .dropdown .notification .noti-body li {
  width: 100%;
}

.header-chat .h-list-footer .input-group {
  .btn-attach {
    padding: 0;
    width: 35px;
    height: 35px;
  }

  .btn-send {
    top: 7px;
  }
}

.main-search {
  padding: 16px 12px;
}

#financialAnalysis {
  font-family: "Montserrat", sans-serif;
}

#rentBreakdown {
  font-family: "Montserrat", sans-serif;
}

#equalTableColumn {
  th {
    width: 20%;
  }

  th:not(:first-child) {
    text-align: center;
  }

  td {
    width: 20%;
  }

  td:not(:first-child) {
    text-align: center;
  }
}

@media only screen and (max-width: 991px) {
  .nav-outside {
    ~ {
      .pcoded-header,
      .pcoded-main-container {
        margin-left: 0;
      }
    }

    &.mob-fixed ~ .pcoded-main-container {
      padding-top: 142px;
    }
  }

  .pcoded-header .navbar-nav > li:last-child {
    padding-right: 35px;
  }
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}

.zoom-container {
  position: relative;
  display: flex;
}

.img-preview-section-container {
  display: none;
}

.zoom-container:hover .img-preview-section-container {
  display: block;
  position: relative;
  left: 50px;
}

.zoom-container:hover .img-preview-section-container {
  left: 100%;
  position: absolute;
  background: #fff;
  border: 1px solid $theme-border;
}

.carousel-inner {
  overflow: visible;
  z-index: 1050;
}

@media only screen and (max-width: 991px) {
  .pcoded-header,
  .pcoded-main-container {
    margin-left: 0;
    width: 100%;
  }
}

.card-header .card-header-right {
  .dropdown-menu.show {
    transform: none !important;
    right: 0 !important;
    top: 100% !important;
    left: auto !important;
  }
}

header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed {
  background: $theme-background;
}

header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed
  .b-brand
  .b-title {
  color: $menu-light-text-color;
}

header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed
  .mobile-menu
  span,
header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed
  .mobile-menu
  span:after,
header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed
  .mobile-menu
  span:before {
  background-color: $menu-light-text-color;
}

header.navbar.pcoded-header.navbar-expand-lg.header-default
  .mobile-menu.on
  span {
  background-color: transparent;
}

body.datta-rtl .pcoded-navbar.theme-horizontal ~ .pcoded-header {
  margin-right: auto;
}

body.datta-rtl .pcoded-navbar.theme-horizontal ~ .pcoded-main-container {
  margin-right: auto;
}

body.datta-rtl
  .pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  > li
  > a:after {
  left: 0;
}

body.datta-rtl
  .pcoded-navbar.theme-horizontal
  .pcoded-inner-navbar
  > li.active:after,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger:after {
  right: 18px;
}

.theme-horizontal .sidenav-horizontal:after,
.theme-horizontal .sidenav-horizontal:before {
  content: "";
  background: $header-dark-background;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 5;
}

.theme-horizontal.navbar-blue .sidenav-horizontal:after,
.theme-horizontal.navbar-blue .sidenav-horizontal:before {
  background: $primary-color;
}

.theme-horizontal.navbar-red .sidenav-horizontal:after,
.theme-horizontal.navbar-red .sidenav-horizontal:before {
  background: $danger-color;
}

.theme-horizontal.navbar-purple .sidenav-horizontal:after,
.theme-horizontal.navbar-purple .sidenav-horizontal:before {
  background: $purple-color;
}

.theme-horizontal.navbar-lightblue .sidenav-horizontal:after,
.theme-horizontal.navbar-lightblue .sidenav-horizontal:before {
  background: $layout-6-bg-color;
}

.theme-horizontal.navbar-dark .sidenav-horizontal:after,
.theme-horizontal.navbar-dark .sidenav-horizontal:before {
  background: $dark-color;
}

.theme-horizontal.navbar-collier .sidenav-horizontal:after,
.theme-horizontal.navbar-collier .sidenav-horizontal:before {
  background: $collier-color;
}

.theme-horizontal.navbar-jll .sidenav-horizontal:after,
.theme-horizontal.navbar-jll .sidenav-horizontal:before {
  background: $jll-color;
}

.theme-horizontal.navbar-pepsico .sidenav-horizontal:after,
.theme-horizontal.navbar-pepsico .sidenav-horizontal:before {
  background: $pepsico-color;
}

.theme-horizontal.navbar-cushman .sidenav-horizontal:after,
.theme-horizontal.navbar-cushman .sidenav-horizontal:before {
  background: $cushman-color;
}

.theme-horizontal .sidenav-horizontal:before {
  left: 100%;
}

.theme-horizontal .sidenav-horizontal:after {
  right: 100%;
}

@media only screen and (max-width: 991px) {
  .pcoded-navbar {
    z-index: 1032;
  }

  body.datta-rtl {
    .pcoded-header,
    .pcoded-main-container {
      margin-right: 0;
    }
  }

  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed {
    background: $header-dark-background;
  }

  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed
    .b-brand
    .b-title {
    color: $header-dark-text-color;
  }

  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed
    .mobile-menu
    span,
  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed
    .mobile-menu
    span:after,
  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed
    .mobile-menu
    span:before {
    background-color: $header-dark-text-color;
  }

  header.navbar.pcoded-header.navbar-expand-lg.header-default
    .mobile-menu.on
    span {
    background-color: transparent;
  }

  body.datta-dark .pcoded-header.header-dark .input-group .input-group-text,
  .pcoded-header.header-dark a,
  .pcoded-header.header-dark .btn,
  .pcoded-header.header-dark .dropdown-toggle {
    color: $dark-layout-font;
  }
}

.to-do-list .checkbox-fade .cr {
  float: left;
  border-radius: 3px;
  width: 22px;
  height: 22px;
}

.to-do-list .checkbox-fade label input[type="checkbox"]:checked + .cr {
  background: #1dd5d2;
}

.user-activity .tab-content {
  padding-top: 5px;
}

.rodal-dialog {
  height: auto !important;
  bottom: auto !important;
  top: 50% !important;
  transform: translateY(-50%);

  .card {
    box-shadow: none;
    margin-bottom: 0px;

    .card-footer {
      padding-bottom: 0px;
    }
  }
}

.carousel-inner {
  overflow: visible;
  z-index: 1050;
}

.form-control[type="file"] {
  height: auto;
  padding-left: 0.45rem;
}

.table {
  .badge {
    margin-right: 0.25rem;
  }
}

.mb-3.row {
  > .col,
  .col.d-flex {
    @media (max-width: 768px) {
      margin-top: 5px;
      margin-bottom: 5px;

      &:nth-child(2) {
        flex-direction: column;
        text-align: left;

        > span {
          justify-content: flex-start !important;

          .form-control {
            max-width: 150px;
          }
        }

        .btn {
          margin-top: 8px;
          max-width: fit-content;
          margin-left: 0 !important;
        }
      }
    }
  }
}

.row.justify-content-between > .col > .d-flex.align-items-center strong {
  margin: 0 5px;
}

@media (max-width: 575px) {
  .row.justify-content-between > *[class*="col-"],
  .row.justify-content-between > .col {
    flex: 100%;

    .pagination {
      justify-content: flex-start !important;
    }

    &:first-child {
      margin-bottom: 8px;
    }

    > .d-flex.align-items-center {
      input {
        width: 40px !important;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

td {
  img.wid-40 {
    max-width: 40px;
  }
}

.new-cust-card {
  @media (max-width: 1024px) {
    .d-inline-block {
      width: calc(100% - 55px);
    }

    .align-middle .status {
      position: relative;
      top: 0;
      left: 0;
    }
  }
}

.table th,
.table td {
  vertical-align: middle;
}

.cover-img-block {
  overflow: hidden;
  z-index: 1;
}

.user-about-block {
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 1200px) {
  .user-card .user-about-block {
    margin-top: -10px;
  }
}

.alert {
  .float-right {
    margin-left: 8px;
    float: none !important;
  }
}

@media (max-width: 768px) {
  .modal-open .modal {
    padding-left: 0 !important;
  }
}

.carousel.slide {
  overflow: unset;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  left: 18px !important;
}

.smooth-dnd-container.horizontal {
  button {
    border: none;
  }
}

.ui-pnotify-icon {
  > span {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 21px;
    height: 21px;
  }
}

.table.table-bordered.table-hover {
  th {
    select.form-control {
      min-width: 140px;
    }
  }
}

.filter-bar .navbar {
  @media (max-width: 690px) {
    padding: 0.5rem 1rem 1rem;

    // #dropdown-basic{
    //   padding-left: 10px;
    // }
    .f-view {
      padding-left: 25px;
      margin-top: 8px;
    }
  }
}

.photoshop-picker {
  @media only screen and (max-width: 575px) {
    display: none;
  }
}

.css-1v494f5-Wrapper {
  z-index: 20 !important;
}

.go2441762052 {
  display: flex;
  justify-content: center;
}

.wizardSidebarBlock {
  position: sticky;
  top: 50px;
}

.wizardGridBlock {
  display: grid;
  grid-template-columns: 1fr 1fr minmax(auto, 33%);
  gap: 0;
}

.wizardGridBlock {
  grid-template-columns: max-content(400px);
}

.wizardGridBlock .wizardMapping {
  grid-row: 1/-1;
}

//  .wizardGridBlock::before{
//   content: "";
//   border-right: 1px solid #dadada;
//   border-bottom: 1px solid #dadada;
//   position: absolute;
//   left: 50%;
//   right: 50%;
//   height: 100%;
//  }
p.filterPills.type,
.projectType
  .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault {
  color: white;
  background-color: #00be9d;
}

p.filterPills.use_type,
.useType
  .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault {
  color: white;
  background-color: #2c97de;
}

p.filterPills.state,
.state
  .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault {
  color: white;
  background-color: #be4600;
}

p.filterPills.status,
.status
  .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault {
  color: white;
  background-color: #f69e00;
}

p.filterPills.city,
.city
  .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault {
  color: white;
  background-color: #e600f6;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon {
  fill: white;
}

.mw-250 {
  max-width: 250px !important;
}

.mw-200 {
  max-width: 200px !important;
}

.filterPillsFlex {
  overflow: hidden;
  max-width: -webkit-fill-available;
}

.filterPills {
  padding: 5px 8px;
  border-radius: 50px;
  color: white;
  margin-bottom: 0;
  // max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  // overflow: hidden;
}

.filterPills.bg-1 {
  color: white;
  background-color: #00be9d;
}

.filterPills.bg-range-1 {
  color: white;
  background-color: #4f9186;
}

.filterPills.bg-range-2 {
  color: white;
  background-color: #723da7;
}

.filterPills.bg-range-3 {
  color: white;
  background-color: #45bd35;
}

.filterPills.bg-date-1 {
  color: white;
  background-color: #0d1064;
}

.filterPills.bg-date-2 {
  color: white;
  background-color: #4b0a39;
}

.filterPills.bg-date-3 {
  color: white;
  background-color: #d56f0f;
}

.filterPills.bg-date-4 {
  color: white;
  background-color: #324e2e;
}

.filterPills.bg-range-4 {
  color: white;
  background-color: #8f2c71;
}

.filterPills.bg-range-5 {
  color: white;
  background-color: #894cea;
}

.filterPills.bg-range-6 {
  color: white;
  background-color: #4f2d07;
}

.filterPills.bg-range-7 {
  color: white;
  background-color: #200c1b;
}

.filterPills.bg-range-8 {
  color: white;
  background-color: #065225;
}

.filterPills.bg-2 {
  color: white;
  background-color: #2c97de;
}

.filterPills.bg-3 {
  color: white;
  background-color: #f69e00;
}

.filterPills.bg-4 {
  color: white;
  background-color: #00be9d;
}

.filterPills.bg-5 {
  color: white;
  background-color: #2c97de;
}

.filterPills.bg-6 {
  color: white;
  background-color: #f69e00;
}

.filterPills.bg-7 {
  color: white;
  background-color: #00be9d;
}

.filterPills.bg-8 {
  color: white;
  background-color: #2c97de;
}

.filterPills.bg-9 {
  color: white;
  background-color: #f69e00;
}

.filterPills:nth-child(10),
.filterPills:nth-child(11),
.filterPills:nth-child(12),
.filterPills:nth-child(13),
.filterPills:nth-child(14) {
  display: none;
}

.gap-10 {
  gap: 10px;
}

.verifiedImageBlock {
  max-width: 70px;
}

.verifiedImageBlock img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cardCustomHeight {
  max-height: 500px;
  min-height: 500px;
}

.cardResetCustomHeight {
  min-height: 350px;
}

.front-side,
.back-side {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
}

.flip-card {
  perspective: 600px;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  -webkit-transform-origin: 100% center;
  -moz-transform-origin: 100% center;
  -ms-transform-origin: 100% center;
  transform-origin: 100% center;
}

.front-side,
.back-side {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;

  -o-transition: transform 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out;
  -webkit-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
}

.front-side {
  z-index: 2;
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-card.flip .front-side {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.back-side {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.flip-card.flip .back-side {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.transparentBG {
  background-color: transparent !important;
  box-shadow: none;
}

.font-bold {
  font-weight: 600;
}

.font-25 {
  font-size: 25px;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiInputLabel-root {
  top: -7px !important;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused {
  padding: 0 !important;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 7px !important;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 7px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  padding-block: 0px !important;
}

// .style-toggler> a::after{
//   content: "\e0ad" !important;
//   animation: none !important;
// }

.signInForm .input-group-prepend {
  min-width: 45px !important;
}

.drp-icon button.dropdown-toggle {
  margin: 0 !important;
}

.dropdownSpacing {
  margin-bottom: 20px !important;
}

.settingBtn Button {
  height: 42px !important;
}

.table th,
.table tr,
.table td {
  width: 100%;
}
