ul.navbar-nav.ml-auto.newHeaderUlLi {
  display: flex;
  flex-direction: unset;
  margin-left: 20px !important;
}

ul.navbar-nav.ml-auto.newHeaderUlLi li {
  margin-right: 20px;
  cursor: pointer;
}
.icon-settings::before {
  content: "";
}
.dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.drp-user.dropdown.d-fle:after {
  content: "\e842";
  font-family: feather;
  font-size: 15px;
  position: absolute;
  top: 0px;
  right: -14px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}
.profile-notification {
  padding: 0;
  line-height: 1.4;
  overflow: hidden;

  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(0px, 64px, 0px);
}
.pro-head {
  color: #fff;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 15px;
  position: relative;
  background: #04a9f5;
}
.pro-head .dud-logout {
  color: #fff;
  padding-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pro-head img {
  width: 40px;
  margin-right: 10px;
}
.img-radius {
  border-radius: 50%;
}
.newLi {
  margin-right: 20px;
}
.pcoded-header .dropdown.show:before {
  content: "c";
  font-family: "pct";
  position: absolute;
  left: -5px;
  top: 47px !important;
  z-index: 1001;
  font-size: 40px;
  line-height: 0;
  color: #fff;
  text-shadow: 0 -2px 2px rgba(65, 76, 78, 0.12);
  display: none !important;
}
/* .pcoded-header .dropdown .profile-notification {
  margin-top: 17px !important;
} */
.progress {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  text-align: center;
  white-space: nowrap;
}
.borderNone h5::after {
  display: none;
}
.targetTable table.k-table.k-grid-table.k-table-md {
  width: 100% !important;
}
.targetTable table.k-table.k-grid-header-table.k-table-md {
  width: 100% !important;
}
i.icon.feather.icon-settings {
  color: #333333;
}
ul#navbar-right li {
  cursor: pointer;
}
.pcoded-header .input-group .input-group-text:hover,
.pcoded-header a:hover,
.pcoded-header .btn:hover,
.pcoded-header.dropdown-toggle:hover {
  color: unset !important;
}
.saveBtnCenter {
  text-align: center !important;
}
li.newLi.settingN {
  margin-right: 0px;
}
li.newLi.riL {
  margin-right: 14px;
}
/* / */
.mrFB {
  margin-right: -4px !important;
}
.arrowAc {
  color: #4e4949;
  margin-left: 12px;
}
.d-flx.inpuTPre {
  display: flex;
}
.d-flx.inpuTPre input {
  width: 40%;
}
.switchDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h4.formNewT {
  color: #111;
  font-weight: 400;
  font-size: 18px;
}
.switchDiv {
  margin: 14px 0px 10px 0px;
}
.switchBlock {
  padding: 0px 75px !important;
}
.col-md-12.switchBlock div div {
  padding: 0px 15px;
}
.d-flx.inpuTPre input {
  width: 25%;
}
.d-flx.inpuTPre {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainBlockSwi {
  padding: 30px;
}
h4.mainTiSw {
  font-size: 16px;
}
h4.formNewT {
  font-size: 14px;
}
.switchBlockN {
  padding: 20px 10px 0px 40px;
}
.switchDiv .MuiFormControl-root.MuiFormControl-fullWidth {
  width: 30%;
}
.borderR {
  border-right: 2px solid #e6e6e6;
}
.col-md-12.centerBtn {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
h5.tiNCss {
  font-weight: 500 !important;
}

p.userName {
  margin-bottom: 0px;
  font-size: 10px;
  margin-left: 50px;
  margin-top: -10px;
}
.swrF {
  margin-right: 10px !important;
  margin-left: -6px !important;
}

.switchDiv.mt-5
  span.MuiSwitch-root.MuiSwitch-sizeMedium.css-julti5-MuiSwitch-root {
  margin-right: 10px !important;
  margin-left: -6px !important;
}
h4.mainTiSw.cr {
  color: #3f3f3f;
}
.switchDiv .MuiFormControl-root.MuiFormControl-fullWidth {
  width: 16%;
}
.progress-bar.bg-warning {
    background: orange !important;
}
.progress-bar.bg-info {
    background: Yellow !important;
    color: black;
}