/**======================================================================
=========================================================================
Template Name: Datta Able Admin Template
Author: Codedthemes
supports: https://codedthemes.support-hub.io/
File: style.css
https://at-ui.github.io/feather-font/ -- FEATHER ICON UNICODE
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text 17, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   Chat, authentication, Maintenance, Maps, Landingpage messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
@charset "UTF-8";
@import "~bootstrap/dist/css/bootstrap";
// General theme contents
@import "partials/variables";
@import "partials/general";
@import "partials/generic";
@import "partials/mixins/function";

// icon
@import "partials/font/next-icon";
@import "partials/font/feather";
@import "partials/font/fontawesome";
@import "partials/font/flaticon";
@import "partials/font/cryptofont";

// important Element
@import "partials/menu/menu-lite";
@import "partials/widget/widget";

// Theme Element
@import "partials/theme-elements/form";
@import "partials/theme-elements/radiobox-checkbox";
@import "partials/theme-elements/labels-badges";
@import "partials/theme-elements/data-tables";
@import "partials/theme-elements/authentication";
@import "partials/theme-elements/button";
@import "partials/theme-elements/breadcrumb-pagination";
@import "partials/theme-elements/nav";

// Other
@import "partials/other/chat";
@import "partials/other/chart";

@import "partials/third-party/third-party";

.filterList {
  display: flex;
  margin-top: 25px;
}

.lightmode-text-purple {
  align-items: center;
  background-color: #ff6358;
  color: white;
  border-radius: 6px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 2px 7px;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.cp {
  cursor: pointer;
}

.circle {
  background-color: #d83b01;
  border-radius: 50%;
  color: #fff;
  height: 2.5em;
  position: relative;
  width: 2.5em;

  border: 1px solid transparent;
}

.circleText {
  text-align: center;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.hover {
  background-color: #0078d4;
}

ul {
  padding-left: 5px;
}

.formatListView {
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 0;
  list-style-type: none;
}

.streetViewMapButton {
  background: rgb(34, 34, 34);
  border: 0px;
  margin: 10px;
  text-transform: none;
  appearance: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  color: white;
  z-index: 200;
  position: fixed;
  right: 65px;
  top: 60px;
}

.k-grid-container tr {
  cursor: pointer;
}

p.marginInfo {
  margin-bottom: 0px !important;
}

.streetViewBlackBg {
  background: black;
  color: white;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.font55 {
  font-size: 55px;
}

.projectTileBlock .k-tilelayout-item-header.k-card-header {
  display: none;
}

.customFilterBlock {
  max-height: 99vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.k-splitbar-draggable-horizontal .k-resize-handle {
  position: absolute !important;
  right: 0px !important;
}

span.k-icon.k-i-caret-alt-left.k-color-inherit,
span.k-icon.k-i-caret-alt-right.k-color-inherit {
  font-size: 30px;
}

.activeFilter {
  color: #0078d4 !important;
  border-bottom: 4px solid #0078d4 !important;
}

.btn-red {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

.projectTileBlock.breadcrum .k-tilelayout.k-grid-flow-col {
  padding: 0px !important;
}

.activeTab {
  background-color: #038fcf;
}

span.k-cell-inner {
  text-align: center;
}

.bg-custom {
  background-color: #fafafa;
}

.nowrap {
  white-space: nowrap !important;
}

.hoverBtn:hover svg path,
.hoverBtn:focus svg path {
  fill: white !important;
}

.projectTileGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.projectBottomGrid {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  gap: 10px;
}

button[aria-label="minimize"],
button[aria-label="maximize"] {
  display: none !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: white !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.border-none {
  border: none !important;
}

.pos-rel {
  position: relative !important;
}

.customLoader {
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  position: absolute;
  inset: 0;
  z-index: 500;
  color: white !important;
}

.customLoader p {
  font-size: 19px !important;
  background: white;
  color: #444;
  padding: 10px 15px;
  border-radius: 5px;
}

// .k-grid-content-sticky {
//   background-color: unset !important;
// }

img.cardIcon {
  width: 15px;
  height: 15px;
  object-fit: cover;
}

.alignCenter {
  align-items: center;
}

.cPadding {
  padding: 7px 14px !important;
}

.customBdrRds {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.k-splitter .k-scrollable {
  overflow: hidden !important;
}

.k-pane.k-scrollable {
  overflow: hidden !important;
}

.placesSearchSuggestion {
  background: white;
  position: absolute;
  z-index: 999;
  width: 100%;
  padding: 2px 15px;
}

.pcoded-header {
  min-height: 10px !important;
}

.pcoded-main-container {
  min-height: 90vh !important;
}

.searchBox {
  position: relative;

  .search-input {
    min-width: 400px;
  }
}

.searchIcon {
  position: absolute;
  top: 4px;
  left: 10px;
  z-index: 1;
}

// span.k-column-title{
//   margin-inline: auto;
// }
.recharts-wrapper,
.recharts-responsive-container {
  margin: 0 auto !important;
}

button.activeTab svg.FilterListIcon>path {
  fill: white !important;
}

.whiteIcon path {
  fill: white !important;
}

tr.k-grouping-row td.fixCell {
  display: none;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption {
  padding-top: 0;
}

.customUserName {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 15px 25px 0px !important;
  margin-bottom: 0 !important;
  padding-top: 15px !important;
  text-transform: inherit !important;
}

.k-grid tr.k-alt {
  background-color: rgba(0, 0, 0, 0.04);
}

.k-spreadsheet {
  width: 100% !important;
}

table.customTable {
  border-collapse: collapse;
  width: 100%;
}

table.customTable td,
table.customTable th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

table.customTable tr:nth-child(even) {
  background-color: #dddddd;
}

// new css
table.customTableMap {
  border-collapse: collapse;
  width: 100%;
}

table.customTableMap td,
table.customTableMap th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

table.customTableMap tr:nth-child(even) {
  background-color: #f5f5f5;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

.form-success {
  background-color: #91ff91;
  padding: 5px;
  border-radius: 5px;
}

.form-success-text {
  color: green;
  font-size: 14px;
  text-align: center;
}

.f600 {
  font-weight: 600 !important;
}

.cardGrey {
  background-color: #f1f1f1 !important;
}

.cardImport {
  border: 2px solid black;
}

.cardWhiteShadow {
  background-color: #f8f8f8 !important;
}

select#object {
  height: calc(2.55rem + 8px) !important;
}

#dropzone {
  position: relative;
  border: 2px solid rgb(168, 168, 168);
  border-radius: 20px;
  color: rgb(63, 62, 62);
  font: normal 16px/150px arial;
  height: 150px;
  margin: 30px auto;
  text-align: center;
  width: 100%;
}

#dropzone.hover {
  border: 10px solid #fe5;
  color: #fe5;
}

#dropzone.dropped {
  background: #222;
  border: 10px solid #444;
}

#dropzone div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#dropzone img {
  border-radius: 10px;
  vertical-align: middle;
  max-width: 95%;
  max-height: 95%;
}

#dropzone [type="file"] {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.iconSize {
  width: 35px;
  height: 35px;
}

.fs22 {
  font-size: 20px;
}

.MuiTreeView-root {
  height: auto !important;
}

.css-9l5vo-MuiCollapse-wrapperInner {
  margin-left: 7px !important;
}

.textDangerBold {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #fbefef !important;
  margin-top: 2px;
}

.textDangerBold p {
  font-weight: 700 !important;
}

.treeTextCenter {
  align-items: center !important;
  align-content: center !important;
  height: 100% !important;
  font-size: 15px;
  color: #858282;
}

.mw20 {
  min-width: 120px;
}

.fixedBottomImportButton {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 25px !important;
  right: 0;
}

// .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root {
//   margin-bottom: 20px;
// }
// .dropdownSpacing .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl .MuiInputBase-input.MuiOutlinedInput-input {
//   margin-bottom: 20px;
// }
.mb-20 {
  margin-bottom: 20px !important;
}

.grey {
  color: grey !important;
}

.table {
  margin-bottom: 0rem;
}

.search-results-container {
  z-index: 2;
  font-size: 12px;

  p {
    font-size: 12px;
  }

  td {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  ul {
    list-style: none;
  }

  li {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  li:before {
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    content: "";
    display: inline-block;
    margin-right: 15px;
  }

  .popover {
    max-width: 400px !important;
  }

  .arrow {
    display: none !important;
  }

  .search-results {
    display: flex;
  }

  .search-results .header {
    flex-grow: 1;
  }
}

.drodownSpacing {
  margin-bottom: 20px !important;
}

.projectDetailStreetView #map {
  min-height: 400px;
}

.height100 {
  height: 100px;
}

.card-header:empty {
  display: none;
}

.mw300 input {
  min-width: 250px;
}

.btnFieldPadding {
  padding: 5px 9px;
}

.chartDownloadBtnAbs {
  position: absolute;
  right: 20px;
  top: -83px;
}

.autocom-box {
  padding: 10px 8px;
  opacity: 1;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.search-input .autocom-box {
  padding: 0;
  opacity: 0;
  pointer-events: none;
  max-height: 280px;
  overflow-y: auto;
}

.autocom-box li {
  list-style: none;
  padding: 8px 12px;
  width: 100%;
  cursor: default;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 600;
  color: #333;
  border-bottom: 1px solid #e7e7e7;
}

.autocom-box li:hover {
  background: #efefef;
}

.autocom-box li.btn:hover {
  background: #0386c3;
}

.switch input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch input[type="checkbox"]+.cr,
.switch input[type="checkbox"]+.form-check-label {
  position: relative;
  display: inline-block;
  transition: 0.4s ease;
  height: 20px;
  width: 35px;
  border: 1px solid #e9eaec;
  border-radius: 60px;
  cursor: pointer;
  z-index: 0;
  top: 12px;
}

.switch input[type="checkbox"]+.cr:after,
.switch input[type="checkbox"]+.cr:before,
.switch input[type="checkbox"]+.form-check-label:after,
.switch input[type="checkbox"]+.form-check-label:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.switch input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch input[type="checkbox"]+.cr,
.switch input[type="checkbox"]+.form-check-label {
  position: relative;
  display: inline-block;
  transition: 0.4s ease;
  height: 20px;
  width: 35px;
  border: 1px solid #e9eaec;
  border-radius: 60px;
  cursor: pointer;
  z-index: 0;
  top: 12px;
}

.switch input[type="checkbox"]+.cr:after,
.switch input[type="checkbox"]+.cr:before,
.switch input[type="checkbox"]+.form-check-label:after,
.switch input[type="checkbox"]+.form-check-label:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.switch input[type="checkbox"]+.cr:before,
.switch input[type="checkbox"]+.form-check-label:before {
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 30px;
}

.switch input[type="checkbox"]+.cr:after,
.switch input[type="checkbox"]+.form-check-label:after {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04),
    0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: #f7f7f7;
  height: 19px;
  width: 19px;
  border-radius: 60px;
}

.switch input[type="checkbox"]:checked+.cr:before,
.switch input[type="checkbox"]:checked+.form-check-label:before {
  background: linear-gradient(-135deg, #1de9b6, #1dc4e9);
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}

.switch input[type="checkbox"]:checked+.cr:after,
.switch input[type="checkbox"]:checked+.form-check-label:after {
  left: 16px;
}

.switch input[type="checkbox"]:disabled+label {
  opacity: 0.5;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
  cursor: not-allowed;
}

.switch.switch-primary input[type="checkbox"]:checked+.cr:before,
.switch.switch-primary input[type="checkbox"]:checked+.form-check-label:before {
  background: #04a9f5;
}

.switch.switch-danger input[type="checkbox"]:checked+.cr:before,
.switch.switch-danger input[type="checkbox"]:checked+.form-check-label:before {
  background: #f44236;
}

.switch.switch-success input[type="checkbox"]:checked+.cr:before,
.switch.switch-success input[type="checkbox"]:checked+.form-check-label:before {
  background: #1de9b6;
}

.switch.switch-warning input[type="checkbox"]:checked+.cr:before,
.switch.switch-warning input[type="checkbox"]:checked+.form-check-label:before {
  background: #f4c22b;
}

.switch.switch-info input[type="checkbox"]:checked+.cr:before,
.switch.switch-info input[type="checkbox"]:checked+.form-check-label:before {
  background: #3ebfea;
}

.switch.switch-alternative input[type="checkbox"]:checked+.cr:before,
.switch.switch-alternative input[type="checkbox"]:checked+.form-check-label:before {
  background: linear-gradient(-135deg, #899fd4, #a389d4);
}

.switch .invalid-feedback {
  position: absolute;
  width: -webkit-max-content;
  width: max-content;
  top: calc(100% + 15px);
}

.switch .form-check input[type="checkbox"]+.form-check-label {
  top: 4px;
}

.ck-focused {
  width: 100%;
  border: none !important;
  box-shadow: none !important;
}

.ck-body-wrapper {
  display: none;
}

.viewModeOnlyHeader {
  margin-left: 0 !important;
  margin-top: 15px !important;
}

.topIcon .dropdown,
.topIcon .dropdown-toggle {
  height: 40px !important;
  line-height: 40px !important;
}

.topIcon .topIconList {
  padding-right: 15px !important;
}

.topIcon .topIconList .pcoded-header {
  margin-left: 0 !important;
}

.pcoded-header .dropdown .notification {
  z-index: 9999;
  width: 50vw !important;
}

.error-text {
  color: #f44236;
}

.fw700 {
  font-weight: 700;
}

.lay-customizer .theme-color,
.theme-color {
  display: block;
  position: relative;
  padding: 5px 5px 0;
  margin-bottom: 10px;
}

.lay-customizer .theme-color>a,
.theme-color>a {
  position: relative;
  width: 45px;
  height: 30px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 8px;
  background: #f4f7fa;
  overflow: hidden;
  box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
}

.lay-customizer .theme-color>a span,
.theme-color>a span {
  width: 100%;
  position: absolute;
}

.lay-customizer .theme-color>a span:after,
.lay-customizer .theme-color>a span:before,
.theme-color>a span:after,
.theme-color>a span:before {
  content: "";
  height: 100%;
  position: absolute;
}

.lay-customizer .theme-color>a span:before,
.theme-color>a span:before {
  width: 30%;
  left: 0;
  background: #3f4d67;
}

.lay-customizer .theme-color>a span:after,
.theme-color>a span:after {
  width: 70%;
  right: 0;
  background: #f4f7fa;
}

.lay-customizer .theme-color>a>span:first-child,
.theme-color>a>span:first-child {
  height: 30%;
  top: 0;
}

.lay-customizer .theme-color>a>span:nth-child(2),
.theme-color>a>span:nth-child(2) {
  height: 75%;
  bottom: 0;
}

.lay-customizer .theme-color>a.active,
.theme-color>a.active {
  box-shadow: 0 0 0 3px #04a9f5;
}

.lay-customizer .theme-color>a.active:before,
.theme-color>a.active:before {
  content: "";
  top: -1px;
  font-size: 20px;
  position: absolute;
  left: 12px;
  font-family: feather;
  z-index: 1001;
  color: #04a9f5;
  transition: 0.3s ease-in-out;
  text-shadow: 0 1px 3px rgba(4, 169, 245, 0.9), 0 3px 8px rgba(0, 0, 0, 0.1);
}

.lay-customizer .theme-color>a.active:after,
.theme-color>a.active:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 100%, 0.4);
}

.lay-customizer .theme-color>a[data-value="menu-light"] span:before,
.theme-color>a[data-value="menu-light"] span:before {
  background: #fff;
}

.lay-customizer .theme-color>a[data-value="dark"] span:after,
.theme-color>a[data-value="dark"] span:after {
  background: #101011;
}

.lay-customizer .theme-color>a[data-value="reset"],
.theme-color>a[data-value="reset"] {
  background: #f44236;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  width: 135px;
}

.lay-customizer .theme-color.small>a,
.theme-color.small>a {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.lay-customizer .theme-color.small>a:before,
.theme-color.small>a:before {
  font-size: 15px;
  left: 5px;
  top: 1px;
}

.lay-customizer .theme-color.small>a>span,
.theme-color.small>a>span {
  display: none;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-blue"],
.lay-customizer .theme-color.header-color>a[data-value="header-blue"]>span:first-child:after,
.theme-color.header-color.small>a[data-value="header-blue"],
.theme-color.header-color>a[data-value="header-blue"]>span:first-child:after {
  background: #04a9f5;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-red"],
.lay-customizer .theme-color.header-color>a[data-value="header-red"]>span:first-child:after,
.theme-color.header-color.small>a[data-value="header-red"],
.theme-color.header-color>a[data-value="header-red"]>span:first-child:after {
  // background: #ff5252;
  background: #e4002b;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-purple"],
.lay-customizer .theme-color.header-color>a[data-value="header-purple"]>span:first-child:after,
.theme-color.header-color.small>a[data-value="header-purple"],
.theme-color.header-color>a[data-value="header-purple"]>span:first-child:after {
  background: #9575cd;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-lightblue"],
.lay-customizer .theme-color.header-color>a[data-value="header-lightblue"]>span:first-child:after,
.theme-color.header-color.small>a[data-value="header-lightblue"],
.theme-color.header-color>a[data-value="header-lightblue"]>span:first-child:after {
  background: #23b7e5;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-dark"],
.lay-customizer .theme-color.header-color>a[data-value="header-dark"]>span:first-child:after,
.theme-color.header-color.small>a[data-value="header-dark"],
.theme-color.header-color>a[data-value="header-dark"]>span:first-child:after {
  background: #323437;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-collier"],
.lay-customizer .theme-color.header-color>a[data-value="header-collier"]>span:first-child:after,
.theme-color.header-color.small>a[data-value="header-collier"],
.theme-color.header-color>a[data-value="header-collier"]>span:first-child:after {
  background: #25408f;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-jll"],
.lay-customizer .theme-color.header-color>a[data-value="header-jll"]>span:first-child:after,
.theme-color.header-color.small>a[data-value="header-jll"],
.theme-color.header-color>a[data-value="header-jll"]>span:first-child:after {
  background: #e30613;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-pepsico"],
.lay-customizer .theme-color.header-color>a[data-value="header-pepsico"]>span:first-child:after,
.theme-color.header-color.small>a[data-value="header-pepsico"],
.theme-color.header-color>a[data-value="header-pepsico"]>span:first-child:after {
  background: #004c97;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-cushman"],
.lay-customizer .theme-color.header-color>a[data-value="header-cushman"]>span:first-child:after,
.theme-color.header-color.small>a[data-value="header-cushman"],
.theme-color.header-color>a[data-value="header-cushman"]>span:first-child:after {
  background: #e4002b;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-blue"]>span:before,
.theme-color.navbar-color>a[data-value="navbar-blue"]>span:before {
  background: #04a9f5;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-red"]>span:before,
.theme-color.navbar-color>a[data-value="navbar-red"]>span:before {
  background: #ff5252;
  // background: #e4002b;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-purple"]>span:before,
.theme-color.navbar-color>a[data-value="navbar-purple"]>span:before {
  background: #9575cd;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-lightblue"]>span:before,
.theme-color.navbar-color>a[data-value="navbar-lightblue"]>span:before {
  background: #23b7e5;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-dark"]>span:before,
.theme-color.navbar-color>a[data-value="navbar-dark"]>span:before {
  background: #323437;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-collier"]>span:before,
.theme-color.navbar-color>a[data-value="navbar-collier"]>span:before {
  background: #25408f;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-jll"]>span:before,
.theme-color.navbar-color>a[data-value="navbar-jll"]>span:before {
  background: #e30613;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-pepsico"]>span:before,
.theme-color.navbar-color>a[data-value="navbar-pepsico"]>span:before {
  background: #004c97;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-cushman"]>span:before,
.theme-color.navbar-color>a[data-value="navbar-cushman"]>span:before {
  background: #e4002b;
}

.lay-customizer .theme-color.brand-color.small>a[data-value="brand-blue"],
.lay-customizer .theme-color.brand-color>a[data-value="brand-blue"]>span:first-child:before,
.theme-color.brand-color.small>a[data-value="brand-blue"],
.theme-color.brand-color>a[data-value="brand-blue"]>span:first-child:before {
  background: #04a9f5;
}

.lay-customizer .theme-color.brand-color.small>a[data-value="brand-red"],
.lay-customizer .theme-color.brand-color>a[data-value="brand-red"]>span:first-child:before,
.theme-color.brand-color.small>a[data-value="brand-red"],
.theme-color.brand-color>a[data-value="brand-red"]>span:first-child:before {
  background: #ff5252;
}

.lay-customizer .theme-color.brand-color.small>a[data-value="brand-purple"],
.lay-customizer .theme-color.brand-color>a[data-value="brand-purple"]>span:first-child:before,
.theme-color.brand-color.small>a[data-value="brand-purple"],
.theme-color.brand-color>a[data-value="brand-purple"]>span:first-child:before {
  background: #9575cd;
}

.lay-customizer .theme-color.brand-color.small>a[data-value="brand-lightblue"],
.lay-customizer .theme-color.brand-color>a[data-value="brand-lightblue"]>span:first-child:before,
.theme-color.brand-color.small>a[data-value="brand-lightblue"],
.theme-color.brand-color>a[data-value="brand-lightblue"]>span:first-child:before {
  background: #23b7e5;
}

.lay-customizer .theme-color.brand-color.small>a[data-value="brand-dark"],
.lay-customizer .theme-color.brand-color>a[data-value="brand-dark"]>span:first-child:before,
.theme-color.brand-color.small>a[data-value="brand-dark"],
.theme-color.brand-color>a[data-value="brand-dark"]>span:first-child:before {
  background: #323437;
}

.lay-customizer .theme-color.active-color>a[data-value="active-default"],
.theme-color.active-color>a[data-value="active-default"] {
  background: linear-gradient(-135deg, #1de9b6, #1dc4e9);
}

.lay-customizer .theme-color.active-color>a[data-value="active-blue"],
.theme-color.active-color>a[data-value="active-blue"] {
  background: #04a9f5;
}

.lay-customizer .theme-color.active-color>a[data-value="active-red"],
.theme-color.active-color>a[data-value="active-red"] {
  background: #ff5252;
  // background: #e4002b;
}

.lay-customizer .theme-color.active-color>a[data-value="active-purple"],
.theme-color.active-color>a[data-value="active-purple"] {
  background: #9575cd;
}

.lay-customizer .theme-color.active-color>a[data-value="active-lightblue"],
.theme-color.active-color>a[data-value="active-lightblue"] {
  background: #23b7e5;
}

.lay-customizer .theme-color.active-color>a[data-value="active-dark"],
.theme-color.active-color>a[data-value="active-dark"] {
  background: #323437;
}

.lay-customizer .theme-color.title-color>a[data-value="title-default"],
.theme-color.title-color>a[data-value="title-default"] {
  background: #fff;
}

.lay-customizer .theme-color.title-color>a[data-value="title-blue"],
.theme-color.title-color>a[data-value="title-blue"] {
  background: #04a9f5;
}

.lay-customizer .theme-color.title-color>a[data-value="title-red"],
.theme-color.title-color>a[data-value="title-red"] {
  background: #ff5252;
}

.lay-customizer .theme-color.title-color>a[data-value="title-purple"],
.theme-color.title-color>a[data-value="title-purple"] {
  background: #9575cd;
}

.lay-customizer .theme-color.title-color>a[data-value="title-lightblue"],
.theme-color.title-color>a[data-value="title-lightblue"] {
  background: #23b7e5;
}

.lay-customizer .theme-color.title-color>a[data-value="title-dark"],
.theme-color.title-color>a[data-value="title-dark"] {
  background: #323437;
}

.lay-customizer .theme-color.navbar-images>a,
.theme-color.navbar-images>a {
  height: 90px;
  background-size: cover;
  background-position: 50%;
}

.lay-customizer .theme-color.navbar-images>a span,
.theme-color.navbar-images>a span {
  display: none;
}

.lay-customizer .theme-color.navbar-images>a.active:before,
.theme-color.navbar-images>a.active:before {
  top: 35px;
  content: "";
}

.lay-customizer .theme-color.bg-images>a,
.theme-color.bg-images>a {
  border-radius: 2px;
  background-size: cover;
  background-position: 50%;
}

.lay-customizer .theme-color.bg-images>a span,
.theme-color.bg-images>a span {
  display: none;
}

.lay-customizer .theme-color.bg-images>a.active:before,
.theme-color.bg-images>a.active:before {
  top: 35px;
  content: "";
}

.lay-customizer .theme-color.prelayout-color>a,
.theme-color.prelayout-color>a {
  margin-bottom: 5px;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l1"]>span:first-child,
.theme-color.prelayout-color>a[data-value="l1"]>span:first-child {
  z-index: 1;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l1"]>span:first-child:before,
.theme-color.prelayout-color>a[data-value="l1"]>span:first-child:before {
  background: linear-gradient(180deg,
      #04a9f5 0,
      #04a9f5 80%,
      #a9b7d0 0,
      #a9b7d0 90%,
      #04a9f5 91%,
      #04a9f5);
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l1"]>span:nth-child(2):before,
.theme-color.prelayout-color>a[data-value="l1"]>span:nth-child(2):before {
  background: linear-gradient(90deg,
      #3f4d67 0,
      #3f4d67 20%,
      #a9b7d0 25%,
      #a9b7d0 30%,
      #3f4d67 0,
      #3f4d67);
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l2"]>span:first-child:before,
.theme-color.prelayout-color>a[data-value="l2"]>span:first-child:before {
  background: #3f4d67;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l2"]>span:nth-child(2):before,
.theme-color.prelayout-color>a[data-value="l2"]>span:nth-child(2):before {
  background: linear-gradient(-135deg, #1de9b6, #1dc4e9);
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l2-2"]>span:first-child:before,
.theme-color.prelayout-color>a[data-value="l2-2"]>span:first-child:before {
  background: #3f4d67;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l2-2"]>span:nth-child(2):before,
.theme-color.prelayout-color>a[data-value="l2-2"]>span:nth-child(2):before {
  background: linear-gradient(-135deg, #899fd4, #a389d4);
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l3"]>span:first-child:before,
.theme-color.prelayout-color>a[data-value="l3"]>span:first-child:before {
  background: #3f4d67;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l3"]>span:nth-child(2):before,
.theme-color.prelayout-color>a[data-value="l3"]>span:nth-child(2):before {
  background: #04a9f5;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l4"]>span:first-child:before,
.theme-color.prelayout-color>a[data-value="l4"]>span:first-child:before {
  background: linear-gradient(-135deg, #1de9b6, #1dc4e9);
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l4"]>span:nth-child(2):before,
.theme-color.prelayout-color>a[data-value="l4"]>span:nth-child(2):before {
  background: #212224;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l4-2"]>span:first-child:before,
.theme-color.prelayout-color>a[data-value="l4-2"]>span:first-child:before {
  background: linear-gradient(-135deg, #899fd4, #a389d4);
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l4-2"]>span:nth-child(2):before,
.theme-color.prelayout-color>a[data-value="l4-2"]>span:nth-child(2):before {
  background: #212224;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l5-h"]>span:first-child,
.theme-color.prelayout-color>a[data-value="l5-h"]>span:first-child {
  height: 40%;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l5-h"]>span:first-child:after,
.lay-customizer .theme-color.prelayout-color>a[data-value="l5-h"]>span:first-child:before,
.theme-color.prelayout-color>a[data-value="l5-h"]>span:first-child:after,
.theme-color.prelayout-color>a[data-value="l5-h"]>span:first-child:before {
  background: linear-gradient(#04a9f5 50%, #fff 0);
  box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
  z-index: 1;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l5-h"]>span:nth-child(2),
.theme-color.prelayout-color>a[data-value="l5-h"]>span:nth-child(2) {
  height: 60%;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l5-h"]>span:nth-child(2):after,
.lay-customizer .theme-color.prelayout-color>a[data-value="l5-h"]>span:nth-child(2):before,
.theme-color.prelayout-color>a[data-value="l5-h"]>span:nth-child(2):after,
.theme-color.prelayout-color>a[data-value="l5-h"]>span:nth-child(2):before {
  background: #f4f7fa;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l-lite"]>span:first-child:after,
.lay-customizer .theme-color.prelayout-color>a[data-value="l-lite"]>span:first-child:before,
.theme-color.prelayout-color>a[data-value="l-lite"]>span:first-child:after,
.theme-color.prelayout-color>a[data-value="l-lite"]>span:first-child:before {
  background: #23b7e5;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l-lite"]>span:nth-child(2):before,
.theme-color.prelayout-color>a[data-value="l-lite"]>span:nth-child(2):before {
  background: #fff;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l6"]>span:first-child,
.theme-color.prelayout-color>a[data-value="l6"]>span:first-child {
  height: 50%;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l6"]>span:first-child:after,
.theme-color.prelayout-color>a[data-value="l6"]>span:first-child:after {
  background: #23b7e5;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l6"]>span:first-child:before,
.theme-color.prelayout-color>a[data-value="l6"]>span:first-child:before {
  background: linear-gradient(#23b7e5 50%, #fff 0);
  z-index: 1;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l6"]>span:nth-child(2),
.theme-color.prelayout-color>a[data-value="l6"]>span:nth-child(2) {
  height: 50%;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l6"]>span:nth-child(2):after,
.theme-color.prelayout-color>a[data-value="l6"]>span:nth-child(2):after {
  background: #f4f7fa;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l6"]>span:nth-child(2):before,
.theme-color.prelayout-color>a[data-value="l6"]>span:nth-child(2):before {
  background: #fff;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l8"]>span:first-child:after,
.lay-customizer .theme-color.prelayout-color>a[data-value="l8"]>span:first-child:before,
.theme-color.prelayout-color>a[data-value="l8"]>span:first-child:after,
.theme-color.prelayout-color>a[data-value="l8"]>span:first-child:before {
  background: #23b7e5;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l8"]>span:nth-child(2):after,
.theme-color.prelayout-color>a[data-value="l8"]>span:nth-child(2):after {
  background: #f4f7fa;
}

.lay-customizer .theme-color.prelayout-color>a[data-value="l8"]>span:nth-child(2):before,
.theme-color.prelayout-color>a[data-value="l8"]>span:nth-child(2):before {
  background: linear-gradient(#fff,
      #fff 5px,
      rgba(35, 183, 229, 0.5) 0,
      rgba(35, 183, 229, 0.5) 0,
      rgba(35, 183, 229, 0.5) 8px,
      #fff 0,
      #fff);
}

.text-black,
.text-black:hover {
  color: black !important;
}

.fieldCustomSurveyLabel {
  margin-top: 10px;
  font-weight: 600;
}

.home-card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.pabs {
  position: absolute;
  right: -10px;
  top: -15px;
}

.projectListBtnGroup button {
  padding: 3px 5px !important;
  margin: 0;
}

.commentGroupHeading {
  background: #e7e7e7;
  padding: 5px 10px;
  font-weight: 600;
  color: gray;
}

.refreshAbsBtn {
  position: absolute;
  left: -25px;
  top: -20px;
}

table.keyDateManagment {
  width: 100%;
}

.tableHeading {
  font-weight: 800;
  font-size: 18px;
  background-color: #505cbc;
  color: white;
}

table.keyDateManagment,
.keyDateManagment th,
.keyDateManagment td {
  border: 1px solid #cccccc;
  border-collapse: collapse;
  padding: 10px;
}

.keyDateManagment th,
.keyDateManagment td {
  // padding: 15px;
  text-align: left;
}

.keyDateManagment tr:nth-child(even) {
  background-color: #f2f2f2;
}

.TablesKeyDates {
  width: 100%;
  outline: 1px solid #cccccc;
  border-radius: 10px;
  overflow: hidden;
  border-radius: 10px;
}

.tableHeading,
.buidlingInfo {
  padding: 15px;
}

.HeadingTables {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr;
  outline: 1px solid #cccccc;
}

.buidlingData {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr;
  outline: 1px solid #cccccc;
  margin-bottom: 45px;
  background-color: #ffc3c3;
}

.buidlingInfo,
.LeaseDataChild {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.buidlingInfo,
.LeaseData,
.keydatesData,
.projectsData {}

.buidlingInfo,
.LeaseDataChild,
.fixedHeightTable {
  margin: 5px !important;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
}

.buidlingInfo,
.LeaseData,
.tableHeading {
  outline: 1px solid #cccccc;
}

.buidlingInfo {
  // background: #f2fdfe;
}

.LeaseDataChild {
  // background: #e1ebff;
  margin: 0;
  padding: 15px;
  height: 300px;
  border: 1px solid #cccccc;
}

// .keyDateManagment {
//   height: 300px;
// }

.projectListBtnGroup .dropdown-toggle::after {
  border: none;
}

.fixedHeightTable {
  height: 300px;
  overflow-y: auto;
  outline: 1px solid #cccccc;
}

.bold {
  font-weight: 800;
}

.leaseFilterGroup,
.keyDateGroup,
.projectsGroup {
  border: 2px solid #e7e7e7;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 15px 15px 5px;
}

.leaseDataGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  text-align: center;
}

.keydateManagmentGrid {
  display: grid;
  grid-template-columns: 2.1fr 3fr 0.8fr;
  gap: 10px;
}

.keyDateDataTable {
  max-width: 100%;
  overflow-x: auto;
  padding: 0;
}

.projectDataGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  grid-template-rows: 155px;
}

.projectDataGrid .card {
  height: fit-content;
  min-height: 155px;
}

.projectDataTable:nth-child(2n + 1) {
  background-color: white;
  border: 1px solid #e7e7e7;
}

.projectDataTable:nth-child(2n) {
  background-color: white;
  border: 1px solid #e7e7e7;
}

.projectDataTable .card-title {
  // color: white;
}

.leaseDataBlock .card {
  background-color: white;
  border: 1px solid #e7e7e7;
  // border-radius: 5px;
}

.leaseDataBlock,
.keyDateDataTableBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.keyDateDataTable {
  min-height: 153px;
  overflow-y: auto;
}

.keyDateTable th,
.keyDateTable tr,
.keyDateTable td {
  padding: 6px !important;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(0, 0, 0, 0.04);
}

.leaseDataBlock .card {
  height: 153px;
}

.keyDateDataTableBlock .card,
.leaseDataBlock .card,
.projectDataGrid .card {
  margin-bottom: 0 !important;
}

.leaseDataBlock .card-body,
.projectDataTable .card-body {
  padding: 5px !important;
}

.keyDateDataTableBlock .card-body {
  padding: 0px !important;
}

.borderLeft {
  border-left: 1px solid gray;
  margin-inline: 10px;
  height: 20px;
}

.lightgray {
  color: #bfbfbf;
}

.darkgray {
  color: #323232;
}

.leaseTitle,
.projectKey {
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
  color: #bfbfbf;
}

.leaseTitle {
  margin-top: 4px;
  text-transform: uppercase;
}

.leaseValue,
.projectValue {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 600;
}

.leaseHeading,
.projectHeading {
  color: #888;
  font-weight: 600;
  text-transform: uppercase;
}

.keyDateManagement .hide {
  display: none;
}

.keyDateManagement .card-body {
  padding: 10px 10px 15px;
}

.projectHeadingOverflow {
  max-width: calc(100% - 50px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.buttonGap {
  grid-gap: 10px;
  gap: 17px;
}

.projectDataTable.Active .card-header {
  background: #f6fef6;
}

.projectDataTable.Inactive .card-header {
  background: #fafafa;
}

.projectDataTable.Complete .card-header {
  background: #f7f8ff;
}

.projectDataTable.On-Hold .card-header {
  background: #fffbe5;
}

.table.fixed {
  table-layout: fixed;
}

// table.fixed th:nth-of-type(1) {
//   min-width: 100px;
//   max-width: 100px;
// }

.keyDateManagement div.px-1.py-0.m-0 {
  border: none !important;
}

.keyDateManagement table {
  border: 1px solid black;
  table-layout: fixed;
}

.customTableMap th:nth-child(1) {
  width: 100px;
}

.customTableMap th:nth-child(2) {
  width: 50px;
}

.customTableMap th:nth-child(3) {
  min-width: 450px;
}

.customTableMap th:nth-child(4) {
  min-width: 450px;
}

.customTableMap th:nth-child(5) {
  min-width: 450px;
}

.customTableMap th:nth-child(6) {
  min-width: 450px;
}

.customTableMap th:nth-child(7) {
  min-width: 450px;
}

.customTableMap th:nth-child(8) {
  min-width: 450px;
}

.customTableMap th:nth-child(9) {
  min-width: 450px;
}

.customTableMap th:nth-child(10) {
  min-width: 450px;
}

.customTableMap th:nth-child(11) {
  min-width: 450px;
}

.customTableMap th:nth-child(12) {
  min-width: 450px;
}

.customTableMap th:nth-child(13) {
  min-width: 450px;
}

.keyDateTable th:nth-child(1) {
  width: 70px;
}

.keyDateTable th:nth-child(2) {
  width: 140px;
}

.keyDateTable th:nth-child(3),
.keyDateTable th:nth-child(4),
.keyDateTable th:nth-child(5),
.keyDateTable th:nth-child(6) {
  width: 95px;
}

.keyDateTable th:nth-child(7) {
  width: 140px;
}

.keyDateTable th:nth-child(8) {
  width: 450px;
}

.keyTable th:nth-child(8) {
  width: 140px !important;
}

.keyTable th:nth-child(1) {
  width: 90px !important;
}

.optimizationTableDetail th:nth-child(1) {
  width: 100px;
}

.optimizationTableDetail th:nth-child(2) {
  width: 300px;
}

.optimizationTableDetail th:nth-child(3),
.optimizationTableDetail th:nth-child(4),
.optimizationTableDetail th:nth-child(5),
.optimizationTableDetail th:nth-child(6),
.optimizationTableDetail th:nth-child(7),
.optimizationTableDetail th:nth-child(8),
.optimizationTableDetail th:nth-child(9) {
  min-width: 150px;
  max-width: 150px;
}

.leaseFadeOut,
.projectFadeOut {
  background: #e7e7e7 !important;
  font-style: italic;
}

.leaseFadeOut .card {
  background: #e7e7e7 !important;
}

.powerText {
  font-size: 8px;
  color: black !important;
  padding-top: 3px;
}

.CustomLayoutOne tr:nth-of-type(2n),
.CustomLayoutOne tr:hover {
  background-color: rgba(4, 169, 245, 0.05) !important;
}

.CustomLayoutTwo tr:nth-of-type(2n),
.CustomLayoutTwo tr:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.gridAutoRequirement {
  display: grid;
  grid-template-columns: minmax(200px, 250px) minmax(200px, 250px) minmax(200px, 250px);
  grid-gap: 15px;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.budgetTrackingGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.invoiceAndChangeOrdersGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.budgetTableSelected {
  background-color: #a8e0fa !important;
}

.keyDateTable.budgetTrackingTable th:nth-child(1) {
  width: 35px;
}

.projectFields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.compositeview .fieldCards.card-body {
  padding: 15px 15px 35px 15px !important;
}

.fieldHeader {
  font-weight: 600;
  color: #212224;
}

.topMiddleSections {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.compositeViewMaps .tab-content {
  padding: 0px !important;
}

.fieldInfo .label.pl-0.pb-0.mb-0 {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  display: contents;
}

.fieldHeader {
  cursor: pointer;
}

.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.grid5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.grid8 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.compositeview .tab-content {
  padding: 10px 30px;
}

.gridMinMax2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
}

.stepperRequirement {
  display: flex;
}

.mapStepper {
  display: flex;
}

span.stepperRequirement {
  padding: 8px 25px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  margin-right: 15px;
}

.stepperActice {
  background: #04a9f5;
  color: white;
  font-weight: 600;
}

.progressbar {
  counter-reset: step;
  width: 100%;
  display: flex;
  cursor: pointer;
}

.progressbar li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  padding: 0 0.7rem;
  cursor: pointer;
  width: 100%;
  color: silver;
  border-bottom: 4px solid silver;
  font-weight: 600;
  font-size: 16px;
}

.progressbar li.active {
  color: black;
  border-bottom: 4px solid #33c3f0;
}

.progressbar li:before {
  /* CSS for creating steper block before the li item*/
  position: relative;
  bottom: -3.99rem;
  float: left;
  left: 50%;
  content: "•";
  color: white;
  background-color: #33c3f0;
  width: 1.2em;
  line-height: 1.4em;
  border-radius: 1.2em;
}

.progressbar li:after {
  /* CSS for creating horizontal line*/
  content: "\00a0\00a0";
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active {
  color: #33c3f0;
}

.progressbar li.active:before {
  border-color: #27ae60;
}

// .progressbar li.active+li:after {
//   background-color: #27ae60;
// }
.compositeMainGrid {
  display: grid;
  grid-template-columns: 1.5fr 4fr 2fr;
  gap: 5px;
}

.breadCrumbAbs {
  position: absolute;
  top: 0;
  padding-left: 0;
}

.mapAndStreet .tab-content {
  padding: 0 !important;
}

.buildingAndLeasesTab .tab-content {
  padding: 0 !important;
}

.smallOutlineBtn {
  border: 1px solid black !important;
  font-size: 10px !important;
  margin: 0 5px !important;
}

button.p-0.m-0.k-button.k-button-md.k-rounded-md {
  border: none;
}

.grid3 .fieldInfo {
  max-width: 176px;
}

.fixedHeightTab {
  position: relative;
  z-index: 9;
}

.fixedHeightTab ul {
  background: white;
  z-index: 1;
  /* padding: 25px; */
  // width: 340px;
}

.fixedHeightTab ul li {
  padding: 15px 25px;
  // border-bottom: 1px solid red;
  list-style: none;
  cursor: pointer;
  font-weight: 600;
}

// .fixedHeightTab ul li:nth-child(even) {
//   background-color: #f6f6f6;
// }

.compositeViewGridTab {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 15px;
}

.fixedWidthBlock {
  // max-width: calc(84vw - 50px);
  width: 100%;
}

.customTab {
  border-bottom: 1px solid #f5f5f5;
}

.customTab:hover {
  background: #f5f5f5;
}

.customTab.active {
  background-color: #25408f;
  color: white;
  position: relative;
}

.customTab.active::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  left: 5px;
  top: 0;
  bottom: 0;
  background-color: white;
}

.grayBG {
  background-color: #f6f6f6;
}

.customTab.active .grayBG {
  background-color: #25408f !important;
}

.subMenuComposite p {
  font-size: 14px;
  padding: 5px 25px;
  font-weight: 600;
  margin-bottom: 0px;
  margin: 3px;
}

.fixedWidthBlock .card {
  margin-bottom: 10px;
}

.activeChildSubMenu {
  background: white;
  color: black;
}

.tabMenuSingle {
  padding: 10px 35px;
  cursor: pointer;
}

.tabMenuSingle.active {
  background: $collier-color !important;
  border-color: $collier-color !important;
  color: white;
}

.keyDateGridMain {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
}

.projectGridKeyDate {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.LeaseGridKeyDate {
  display: grid;
  grid-template-columns: 2fr 0.2fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.buildingGridKeyDate {
  display: grid;
  grid-template-columns: 2.4fr 0.2fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.keyDateManagement .card-title {
  width: 100%;
}

.leaseWithProjectGrid,
.keyDateWithProjectGrid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 15px;
}

.form-error {
  color: red;
}

.leaseWithProjectOnly {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 15px;
  gap: 15px;
  padding: 0 10px;
}

.keyDateInfoGrid {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.keyDateWithProjectGrid .card {
  margin-bottom: 0px;
}

.tmValue {
  white-space: nowrap;
  overflow: hidden;
}

.grid6 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}

.keyDateManagement .leaseTitle {
  margin-top: 0;
}

.grid1 {
  display: grid;
  grid-template-columns: 1fr;
}

.css-1u2w381-MuiModal-root-MuiDrawer-root {
  z-index: 99 !important;
}

.grid8 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.budgetTable .carousel {
  z-index: 0;
}

.budgetTable .carousel-control-prev-icon,
.budgetTable .carousel-control-prev,
.customCarousel .carousel-control-prev-icon,
.customCarousel .carousel-control-prev {
  background-image: url("https://cdn-icons-png.flaticon.com/512/271/271220.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  top: 75px;
  left: -25px;
}

.budgetTable .carousel-control-next-icon,
.budgetTable .carousel-control-next,
.customCarousel .carousel-control-next-icon,
.customCarousel .carousel-control-next {
  background-image: url("https://cdn-icons-png.flaticon.com/512/271/271228.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  top: 75px;
  right: -25px;
}

.customCarousel .carousel-control-prev-icon,
.customCarousel .carousel-control-prev {
  background-image: url("https://cdn-icons-png.flaticon.com/512/271/271220.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  top: 75px;
  left: -5px;
}

.customCarousel .carousel-control-next-icon,
.customCarousel .carousel-control-next {
  background-image: url("https://cdn-icons-png.flaticon.com/512/271/271228.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  top: 75px;
  right: -5px;
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 9999999;
}

.versionName {
  margin-bottom: 0;
  margin-left: 15px;
  padding: 3px;
  font-size: 15px;
  font-weight: 600;
}

.overflow-hidden {
  overflow: hidden;
}

.scrollX {
  display: flex;
  overflow-x: auto;
  padding: 25px 0;
  overflow-y: none;
}

.surveyCardLandlord {
  min-width: 300px;
  max-width: 100%;
  margin-right: 25px;
}

.financialCarausal .carousel-control-prev-icon,
.financialCarausal .carousel-control-prev,
.financialCarausal .carousel-control-next-icon,
.financialCarausal .carousel-control-next {
  top: 55px !important;
}

.versionHeadingBox {
  padding: 25px !important;
  background: #68d0f333;
}

.surveyGridLandlord {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 15px;
  margin-bottom: 25px;
}

.btnMinWidth {
  min-width: 125px;
}

.loginCarouselBlock {
  // min-height: calc(100vh - 10px);
  height: 100vh;
  height: 100dvh;
  // height: 745px;
}

.loginCarouselBlock img {
  object-fit: cover;
}

.react-dual-listbox button,
.react-dual-listbox select {
  line-height: 6;
}

.rdl-has-filter .rdl-actions {
  padding-top: 50px;
}

.labelWithValueGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.customDrawer {}

.menu-styler {
  .style-toggler {
    >a.customDrawer {
      position: fixed;
      right: 0;
      top: 50vh !important;
      transition: 0.3s ease-in-out;

      &:after {
        // content: '\e890';
        content: "\f086";
        top: -11px;
        font-size: 18px;
        position: absolute;
        right: 4px;
        // font-family: 'feather';
        z-index: 1029;
        line-height: 1;
        color: #fff;
        font-family: "Font Awesome 5 Free";
        // font-weight: 900;
        // content: "\";
        animation: none !important;

        @media all and (-ms-high-contrast: none) {
          line-height: 0.5;
        }
      }
    }
  }
}

.grid7 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.tabBtnView {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
}

.p10 td {
  padding: 8px;
}

.leaseWithProjectGrid .card {
  margin-bottom: 15px;
}

.commentUserName p {
  font-size: 11px;
  color: gray;
}

.commentTextPara P {
  color: gray;
}

.mw400 {
  max-width: 400px;
}

.showAllVersion {
  display: flex;
  // grid-template-columns: 4fr 1fr;
  gap: 15px;
}

.financialCarausal {
  max-width: 100%;
  overflow: auto;
}

.financialAnalysis .checkbox.checkbox-fill input[type="checkbox"]+.form-check-label:after,
.financialAnalysis .checkbox.checkbox-fill input[type="checkbox"]+.cr:after {
  border: 2px solid black;
}

.drodownFinancial {
  position: absolute;
  top: 20px;
  left: 15px;
}

.siteSelectionLabel {
  min-width: 200px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
  color: green;
}

.borderBtn {
  border: 1px solid $collier-color;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.activeCard {
  border: 2px solid #33c3f0 !important;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  // border-radius: 17px;
}

.cardWhiteBorder {
  border: 2px solid #fff !important;
}

.bg-red .btn-primary {
  background: red !important;
  border: 1px solid red;
}

.auth-wrapper .auth-icon:before {
  // background: linear-gradient(-135deg, #e21748 0%, #e91d1e 100%);
}

.customCompositeViewGrid {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 25px;
  position: relative;
}

.mapGridView.customMapView {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 40%;
}

.cardView.customCardView {
  overflow-y: auto;
  max-height: 500px;
  overflow-x: hidden;
}

.customCarouselFix {
  width: 100%;
  height: 255px;
  overflow: hidden;
}

.customCarouselFix img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-inner {
  height: 100%;
}

th.k-header.text-right {
  justify-content: end;
}

.br15 {
  // border-radius: 15px;
}

.carausalImage {
  object-fit: cover;
  width: 100%;
}

/* Style the video: 100% width and height to cover the entire window */
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

#myVideo:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-color: rgba(255, 255, 255, 0.1);
}

/* Add some content at the bottom of the video/page */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

.customSliderFix {
  max-height: 218px;
}

.btnRadius {
  border-radius: 5px !important;
}

@keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

.statusWithRed {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.d-grid {
  display: grid !important;
}

.financialAnalysis {
  min-width: 25vw;
}

.k-grid td.k-detail-cell {
  padding: 8px 0;
}

.keyDateBorder {
  border: 1px solid red;
}

.blinking {
  border: 2px solid red;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: 100;
}

@keyframes blinking {
  50% {
    border-color: grey;
  }
}

.keyDateStatusDrp {
  height: 33px !important;
  padding: 4px;
}

.kdmHeader {
  padding: 10px !important;
}

.keyDateWithProjectGrid {
  gap: 6px;
}

.mbLeaseKdm {
  margin-bottom: 6px !important;
}

.leaseWithProjectGrid {
  margin-top: 6px !important;
}

.keyDateMap img {
  max-height: 155px !important;
}

.pabr0 {
  position: absolute;
  left: 0;
  margin-top: -15px;
}

.rentFormGrid {
  display: grid;
  grid-template-columns: 5fr 0.7fr;
}

.cancelIconBtn {
  border: 1px solid #e7e7e7;
}

.cancelIconBtn svg {
  color: red;
}

.noWrap {
  white-space: nowrap;
}

.badge-orange {
  background-color: #b6e2a1;
  color: #101011;
}

.badge-yellow {
  background-color: #53bf9d;
  color: white;
}

.badge-dark-red {
  background-color: #dc3535;
  color: white;
}

.badge-salmon {
  background-color: #fcddb0;
  color: #101011;
}

.badge-danger-light {
  background-color: #ff9f9f;
  color: white;
}

.blueColorText {
  background: #e8ebff;
  color: black;
}

.table.customTableMap th,
.table.customTableMap tr,
.table.customTableMap td {
  width: fit-content;
}

.pie-row {
  justify-content: space-between;
  display: flex;
  height: 150px;
  margin-top: 15px;
}

.pie-row .pie-wrap {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100px;
  background-color: #fff;
  border: 2px solid white;
  height: 110px;
  text-align: center;
  border-radius: 3px;
}

.pie-row .pie-wrap.selected {
  background: #eee;
  border: 2px solid #ddd;
}

.pie-row .pie-wrap:hover {
  border: 2px solid #4d99ef;
}

.pie-row .pie-wrap .dayWrap p {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}

.pie-row .pie-wrap .dayWrap p:last-child {
  font-size: 11px;
  font-weight: normal;
  color: #999;
  margin-top: -3px;
}

.siteSelectionTemplate .rdl-control {
  min-height: 1000px !important;
}

.siteSelectionTemplateClause .rdl-control {
  min-height: 1100px !important;
}

.siteSelectionTemplate .react-dual-listbox button {
  line-height: 4 !important;
}

.siteSelectionTemplateClause .react-dual-listbox button {
  line-height: 4 !important;
}

.siteSelectionTemplateClause .react-dual-listbox *,
.siteSelectionTemplate .react-dual-listbox * {
  box-sizing: border-box;
  font-size: 16px;
  margin-bottom: 5px;
}

.templateGridSurvey {
  display: grid;
  grid-template-columns: 2.7fr 1fr;
  gap: 25px;
}

/* non-sortable headers */
.k-grid th.center-me {
  text-align: center;
}

/* sortable headers */
th.center-me .k-cell-inner>.k-link {
  justify-content: center;
}

.optimizationListBorder {
  // min-width: 150px !important;
  // max-width: 220px !important;
  min-width: 25% !important;
  max-width: 33.33% !important;
}

.compositeTopMinmize {
  max-height: 63px;
  margin-bottom: 5px;
  overflow: hidden;
  border-bottom: 10px solid white;
}

.circleIcon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.text-success.circleIcon {
  background: #28a745 !important;
}

.text-warning.circleIcon {
  background: #ffc107 !important;
}

.text-danger.circleIcon {
  background: #dc3545 !important;
}

.dualListBoxTemplate option:empty {
  display: none;
}

.globalInputOptimization div {
  min-width: 120px;
}

.optimizationTable th.k-header.center-me {
  background: antiquewhite !important;
}

th.k-table-th.k-header.center-me.bg-green {
  background-color: #e8f4f8 !important;
}

.optimizationTopIcon {
  position: absolute;
  top: 10px;
  right: 1%;
}

.bg-green {
  background-color: #e8f4f8 !important;
}

.MuiDrawer-root {
  z-index: 999 !important;
}

.keyDateBuildingSelected {
  border: 2px solid salmon !important;
}

.serviceCard {
  background: #e7e7e7;
  padding: 15px 25px;
  cursor: pointer;
  border-radius: 5px;
}

.contentBlock h6 {
  font-size: 17px;
  margin-bottom: 0;
}

.serviceCard.active {
  background-color: #e8f4f8;
}

div.colorBox {
  height: 24px;
  width: 40px;
}

.buildingDetail .tab-content {
  padding: 5px 15px 15px 15px;
}

.menu-styler {
  .style-toggler {
    >a.customDrawer.uploadFileIcon {
      position: fixed;
      right: 0;
      top: 65vh !important;
      transition: 0.3s ease-in-out;

      &:after {
        content: "\f1c5";
        top: -11px;
        font-size: 18px;
        position: absolute;
        right: 4px;
        font-family: "Font Awesome 5 Free";
        z-index: 1029;
        line-height: 1;
        color: #fff;

        animation: none !important;

        @media all and (-ms-high-contrast: none) {
          line-height: 0.5;
        }
      }
    }
  }
}

.menu-styler {
  .style-toggler {
    >a.customDrawer.tourDrawerIcon {
      position: fixed;
      right: 0;
      top: 35vh !important;
      transition: 0.3s ease-in-out;

      &:after {
        // content: '\e903';
        content: "\3f";
        top: -11px;
        font-size: 18px;
        position: absolute;
        right: 4px;
        font-family: "feather";
        z-index: 1029;
        line-height: 1;
        color: #fff;
        font-family: "Font Awesome 6 Free";
        animation: none !important;

        @media all and (-ms-high-contrast: none) {
          line-height: 0.5;
        }
      }
    }
  }
}

.grid10 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.serviceCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.serviceCard img {
  max-height: 45px;
  margin-bottom: 15px;
}

.mh500 {
  max-height: 300px;
  overflow-y: scroll;
}

.poweredByText {
  font-weight: 500;
  font-size: 10px;
}

.pcoded-navbar .pcoded-inner-navbar li:hover {
  background: rgba(0, 0, 0, 0.08);
}

.colorBoxSquare {
  width: 60px !important;
  height: 60px !important;
}

.themeColor .card-title {
  display: flex !important;
  align-items: center;
}

.themeColor .card .card-header h5:after {
  content: "";
  background-color: unset;
}

.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation {
  max-height: 280px !important;
}

.bg-light-red img {
  width: 30px;
  margin-left: 10px;
  margin-top: -15px;
}

.commentRecordHeading {
  font-size: 11px;
}

.rdl-list-box .rdl-control-label.rdl-sr-only {
  position: relative;
}

.rdl-filter-container .rdl-control-label.rdl-sr-only {
  display: none;
}

.schemaDataGrid .tab-content {
  padding-top: 15px;
}

.homeCard {
  // max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
}

.financialAnalysisModal label {
  white-space: nowrap;
}

.schemaDataGrid .rdl-control {
  min-height: 500px !important;
}

.s3ImageInline {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 15px;
}

// button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator {
//   right: 0;
//   position: absolute;
// }

// .task-comment {
//   max-height: 500px;
// }

.chartGridLayout {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.optInput {
  padding: 5px 20px !important;
}

.optInput h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.globalInputBlock {
  margin-top: 8px;
}

table.optimizationTable {
  // border-collapse: collapse;
}

table.optimizationTable th,
table.optimizationTable td {
  border: 1px solid rgb(244, 244, 244);
  padding: 8px;
}

table.optimizationTable th:first-child,
table.optimizationTable td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
}

.marketLabel {
  margin-bottom: 35px;
}

.optRecordForm input {
  border-bottom: 1px solid #dedcdc;
  padding: 15px 15px;
}

.p-3 .label {
  min-height: 33px;
  margin-top: 5px !important;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.optRecordForm {
  margin-top: 8px;
}

.optimizationTableMain {
  display: flex;
}

.optimizationTableMain .firstBlock {
  width: 13%;
}

.optimizationTableMain .secondBlock {
  width: 87%;
  padding-left: 15px;
}

.optimizationTableMain .secondBlock.fullBlock {
  width: 100%;
  padding-left: 0;
}

.optCardBody.card-body {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.reducePaddingCardBody.card-body {
  padding: 15px;
}

.optimizationTableMain .h-100.customCarouselFix.active.carousel-item {
  height: 105px !important;
}

input.form-control.optRecordInput {
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
  max-height: 33px;
}

// fieldset {
//   border: 2px solid #1F497D;
//   border-radius: 5px;
//   padding: 0px;
//   margin-top: 15px;
// }

// fieldset legend {
//   color: #111010;
//   padding: 3px 10px;
//   font-size: 15px;
//   border-radius: 5px;
//   box-shadow: 0 0 0 2px #1f497d;
//   margin-left: auto;
// }

fieldset.renewal {
  background: #eef5f4;
  border: 2px solid #bed2cf;
}

fieldset.renewal legend {
  background: #eef5f4;
  box-shadow: 0 0 0 2px #bed2cf;
}

fieldset.expansion {
  background: #f5f9f4;
  border: 2px solid #cfd5cd;
}

fieldset.expansion legend {
  background: #f5f9f4;
  box-shadow: 0 0 0 2px #cfd5cd;
}

fieldset.occTitle {
  background: #ecf2ff;
  border: 2px solid #cfd6f5;
}

fieldset.occTitle legend {
  background: #ecf2ff;
  box-shadow: 0 0 0 2px #cfd6f5;
}

fieldset.housing {
  background: #fdfcf6;
  border: 2px solid #f0ebca;
}

fieldset.housing legend {
  background: #fdfcf6;
  box-shadow: 0 0 0 2px #f0ebca;
}

.keyDateMapView .cardBlock {
  max-height: 75vh;
  overflow: scroll;
}

label.optInputLabel {
  margin-top: 10px;
  margin-bottom: 0;
}

.optRecordForm .card .card-body {
  padding: 3px 15px 10px;
}

.keyDateCardWithMap {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 15px;
}

.tooltipMargin {
  margin-bottom: 8px !important;
}

.verticalCenter {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

select.optInput {
  height: 33px !important;
}

.landlordSurveyGrid {
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(10000, calc(20% - 15px));
  grid-template-rows: minmax(200px, 1fr);
  padding: 0;
}

.firstCardSticky {
  // left: 6%;
  // position: sticky;
  // height: 100vh;
  // z-index: 999;
}

.marketSurveyGridLayout {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 20px;
}

#mapContainer {
  height: "100vh";
}

#clockContainer {
  position: relative;
  margin: auto;
  height: 100px;
  /*to make the height and width responsive*/
  width: 100px;
  background: url(https://media.geeksforgeeks.org/wp-content/uploads/20210302161254/imgonlinecomuaCompressToSizeOmNATjUMFKw-300x300.jpg) no-repeat;
  /*setting our background image*/
  background-size: 100%;
}

#hour,
#minute,
#second {
  position: absolute;
  background: black;
  border-radius: 10px;
  transform-origin: bottom;
}

#hour {
  width: 1.8%;
  height: 25%;
  top: 25%;
  left: 48.85%;
  opacity: 0.8;
}

#minute {
  width: 1.6%;
  height: 30%;
  top: 19%;
  left: 48.9%;
  opacity: 0.8;
}

#second {
  width: 1%;
  height: 40%;
  top: 9%;
  left: 49.25%;
  opacity: 0.8;
}

.mw100 {
  min-width: 120px !important;
}

.card-body button.btn.btn-secondary.px-3.py-2,
.card-header button.btn.btn-secondary.px-3.py-2 {
  padding: 5px 7px !important;
}

.listViewIconChart {
  position: absolute;
  right: 0px;
  top: 10px;
}

table.optimizationTableDetail,
table.optimizationTableDetail td,
table.optimizationTableDetail th {
  border: 0.3px solid #edeaea;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

table.optimizationTableDetail {
  max-width: 95% !important;
  border: none !important;
}

.orangeBorder {
  border: 1px solid red !important;
}

.greenBorder {
  border: 1px solid black !important;
}

.commentsTableGrid {
  display: grid;
  grid-template-columns: 1fr 1.5fr 4fr 1.5fr 1.1fr;
  grid-gap: 20px;
  padding: 10px 15px;
}

.gridNotification {
  display: grid;
  grid-template-columns: 0.8fr 3fr 4fr 1fr 1fr;
  grid-gap: 20px;
  padding: 10px 15px;
}

.optimizationTableLabel {
  background: #c5c5c5;
  padding: 10px;
  margin-bottom: 0;
  text-align: left;
  font-weight: 700;
}

.buildingDetailGrid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
}

.cardFlip {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  margin: -150px;
  float: left;
  perspective: 500px;
}

.cardFlip .content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  transition: transform 1s;
  transform-style: preserve-3d;
}

.cardFlip:hover .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.cardFlip .front,
.cardFlip .back {
  position: absolute;
  height: 100%;
  width: 100%;
  background: white;
  line-height: 300px;
  color: #03446a;
  text-align: center;
  font-size: 60px;
  border-radius: 5px;
  backface-visibility: hidden;
}

.cardFlip .back {
  background: #03446a;
  color: white;
  transform: rotateY(180deg);
}

.parentFlippers {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  transition: transform 2s;
  transform-style: preserve-3d;
}

.parentFlippers.flip {
  transform: rotateY(180deg);
  transition: transform 2s;
}

.parentFlippers.original {
  transform: rotateY(0deg);
  transition: transform 2s;
}

.parentFlippers .first,
.parentFlippers .second {
  // position: absolute;
  inset: 0;
}

.parentFlippers .second {
  transform: rotateY(180deg);
}

.dynamicChart .chartDownloadBtnAbs {
  position: absolute;
  right: 41px;
  top: -53px;
}

.blinkOutline {
  outline: 2px solid #e68888;
  animation: blink 1s;
  animation-iteration-count: 150;
}

.buildingIcons {
  margin-top: -11px;
  margin-bottom: 0;
  margin-right: 25px;
}

.buildingIconManualChart {
  position: absolute;
  right: 5px;
  top: 9px;
}

.optimizeIcons button.btn.btn-secondary.px-3.py-2 {
  padding: 0px 7px 2px !important;
}

// .k-gantt-toggle {
//   display: block !important;
// }

tr.k-state-selected>td {
  background: white !important;
}

.k-task-draghandle {
  display: none !important;
}

.dateLabelGantt {
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

span.stPercent {
  margin-left: 5px;
  font-size: 12px;
  color: #787070;
}

.overflow-hidden {
  white-space: nowrap;
  overflow: hidden;
}

.autocom-box li p.overflow-hidden {
  font-size: 12px;
  color: #848282;
  font-weight: 400;
}

.k-input {
  width: 100% !important;
  border: 1px solid !important;
  border-color: rgba(0, 0, 0, 0.08) !important;
  color: #424242 !important;
  height: 30px !important;
  background-image: linear-gradient(transparent,
      rgba(0, 0, 0, 0.02)) !important;
}

.ganttGridView {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
}

.filterSticky {
  margin: 0;
  position: sticky;
  bottom: 0;
  background: white;
  width: auto;
  margin-left: -15px;
  margin-right: -15px;
  padding: 2px 5px;
  z-index: 9;
}

.joyRideIcon {
  position: absolute;
  right: 106px;
  top: 61px;
}

span.k-resize-handle.k-resize-w {
  display: none;
}

.k-task-dot.k-task-start,
.k-task-dot.k-task-end {
  display: none !important;
}

span.k-task-actions,
span.k-resize-handle.k-resize-e {
  display: none !important;
}

.blackBorder {
  border-color: #6e6e6e !important;
}

table.k-selectable {
  border-collapse: collapse !important;
}

.breadIcon {
  color: gray;
  margin-right: 5px;
}

.breadcrumb-item a {
  color: black;
  font-weight: 700;
  font-size: 9px;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.3rem;
  color: #6c757d;
  content: "\f054" !important;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 8px;
  margin-top: 7px;
}

.addressTopText {
  font-size: 10px;
  margin-left: 5px;
  margin-top: 6px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: white !important;
}

.planCrossIcon {
  position: absolute;
  right: -15px;
  top: -15px;
}

.k-button-icontext .k-icon {
  margin: 0 0px 0 0px !important;
}

.delete-command-icon {
  color: red !important;
}

button.k-button.k-button-icontext.mark-as-complete {
  color: green;
}

button.k-button.k-button-icontext.k-gantt-create {
  display: none;
}

label.createProjectLabel {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}

.k-task-single.k-state-selected .k-task-complete {
  background-color: #656565 !important;
}

.addStageGridTable {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.addStageGridDataTable {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr 0.5fr;
  margin-top: 5px;
}

.addStageGridDataTable>div {
  background: #ffffff;
  margin: 2px;
  padding: 5px;
  border-bottom: 1px solid #e7e7e7;
}

.addStageGridTable>div {
  margin: 4px;
  background: white;
  color: white;
  padding: 3px 9px;
}

.stageAddBgColor {
  background: #827979 !important;
}

.addedNewRecordBg {
  background: #ffffe2 !important;
}

.stageAddBgColor.text-yellow {
  color: #ffffe2 !important;
}

.nav-pills .nav-link {
  border-radius: 0 !important;
}

.vCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectDropdownLayer .MuiFormControl-root {
  padding: 0;
  margin: 0;
  margin-right: 15px;
}

.selectDropdownLayer .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px !important;
}

.siteSelectionDetailGrid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 15px;
}

.chatIconTop {
  position: absolute;
  right: 105px;
  top: 16px;
  z-index: 1;
}

.detailChat {
  top: 8px;
}

.k-task-template {
  background: #2f4f4f !important;
}

.activeGanttMarkAsComplete .k-task-template {
  background: rgb(173, 216, 230) !important;
}

.keyDateMapOptimization {
  background: white;
}

.noDataFound {
  padding: 35px 15px;
  border: 2px solid #f1f1f1;
}

.notFoundIcon {
  font-size: 30px;
}

.topRightMenuIcon {
  font-size: 18px;
  color: #726f6f;
}

.topRightMenuIcon:hover {
  color: #0078d4;
}

.homepageReorder .rdl-list-box.rdl-available {
  display: none;
}

.homepageReorder .rdl-actions-right,
.homepageReorder .rdl-actions-left {
  display: none;
}

.homepageReorder .rdl-control {
  height: 500px;
}

.homepageReorder .rdl-list-box .rdl-control-label.rdl-sr-only {
  display: none;
}

.topIcon .topIconList {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

i.fa.fa-question.feedbackIc {
  color: #828282;
}

.feedbackIc {
  cursor: pointer;
  line-height: 40px !important;
}

ul.noti-body.-flush {
  overflow: hidden !important;
}

.card-border-c-blue.homeCard.card .card-task.card-body {
  min-height: 400px;
}

.topIcon .topIconList {
  padding-right: 0px !important;
}

button.width200.feedbackSubmitBtn.Submitted.btn.btn-primary {
  background-color: green !important;
  border: 1px solid green !important;
}

p.richtextBtn {
  cursor: pointer;
}

.downloadIc i {
  color: #4c4a4a !important;
}

.downloadIc {
  border: 1px solid grey !important;
}

.deleteIc i {
  color: #4c4a4a !important;
}

.deleteIc {
  border: 1px solid grey !important;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 10px;
}

.feedbackSubmitBtnDiv {
  text-align: center;
}

.editorClassName.rdw-editor-main {
  padding: 16px;
}