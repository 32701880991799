nav.pcoded-navbar.menu-light.navbar-default.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .card .card-header h5:after {
  background-color: var(--cushman-color);
}

nav.pcoded-navbar.menu-light.navbar-collier.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .card .card-header h5:after {
  background-color: var(--collier-color);
}

nav.pcoded-navbar.menu-light.navbar-jll.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .card .card-header h5:after {
  background-color: var(--jll-color);
}

nav.pcoded-navbar.menu-light.navbar-pepsico.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .card .card-header h5:after {
  background-color: var(--pepsico-color);
}

//menu toggler designs
nav.pcoded-navbar.menu-light.navbar-default+header.navbar.pcoded-header+div.pcoded-main-container div.modals div.menu-styler div.style-toggler>a:before,
nav.pcoded-navbar.menu-light.navbar-default+header.navbar.pcoded-header+div.pcoded-main-container div.menu-styler div.style-toggler>a:before {
  color: var(--cushman-color);
  text-shadow: 1px 0 2px var(--cushman-color), 3px 0 5px rgb(0 0 0 / 10%);
}

nav.pcoded-navbar.menu-light.navbar-collier+header.navbar.pcoded-header+div.pcoded-main-container div.modals div.menu-styler div.style-toggler>a:before,
nav.pcoded-navbar.menu-light.navbar-collier+header.navbar.pcoded-header+div.pcoded-main-container div.menu-styler div.style-toggler>a:before {
  color: var(--collier-color);
  text-shadow: 1px 0 2px var(--collier-color), 3px 0 5px rgb(0 0 0 / 10%);
}

nav.pcoded-navbar.menu-light.navbar-jll+header.navbar.pcoded-header+div.pcoded-main-container div.modals div.menu-styler div.style-toggler>a:before,
nav.pcoded-navbar.menu-light.navbar-jll+header.navbar.pcoded-header+div.pcoded-main-container div.menu-styler div.style-toggler>a:before {
  color: var(--jll-color);
  text-shadow: 1px 0 2px var(--jll-color), 3px 0 5px rgb(0 0 0 / 10%);
}

nav.pcoded-navbar.menu-light.navbar-pepsico+header.navbar.pcoded-header+div.pcoded-main-container div.modals div.menu-styler div.style-toggler>a:before,
nav.pcoded-navbar.menu-light.navbar-pepsico+header.navbar.pcoded-header+div.pcoded-main-container div.menu-styler div.style-toggler>a:before {
  color: var(--pepsico-color);
  text-shadow: 1px 0 2px var(--pepsico-color), 3px 0 5px rgb(0 0 0 / 10%);
}

//login button design plus border fix
.loginButton,
.loginButton:hover,
.loginButton:focus,
.loginButton:focus-visible,
.loginButton:visited {
  background-color: var(--collier-color) !important;
  border-color: var(--collier-color) !important;
}

nav.pcoded-navbar.menu-light.navbar-cushman.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-cushman.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  border: 1px solid var(--cushman-color);
}

nav.pcoded-navbar.menu-light.navbar-jll.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-jll.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  border: 1px solid var(--jll-color);
}

nav.pcoded-navbar.menu-light.navbar-collier.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-collier.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  border: 1px solid var(--collier-color);
}

nav.pcoded-navbar.menu-light.navbar-pepsico.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-pepsico.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  border: 1px solid var(--pepsico-color);
}

//button states

nav.pcoded-navbar.menu-light.navbar-default.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-default.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  background-color: #e4002b;
  border-color: #e4002b;
}

nav.pcoded-navbar.menu-light.navbar-blue.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-blue.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  background-color: #04a9f5;
  border-color: #04a9f5;
}

nav.pcoded-navbar.menu-light.navbar-red.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-red.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  background-color: #ff5252;
  border-color: #ff5252;
}

nav.pcoded-navbar.menu-light.navbar-purple.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-purple.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  background-color: #9575cd;
  border-color: #9575cd;
}

nav.pcoded-navbar.menu-light.navbar-lightblue.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-lightblue.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  background-color: #23b7e5;
  border-color: #23b7e5;
}

nav.pcoded-navbar.menu-light.navbar-dark.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-dark.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  background-color: #323437;
}

nav.pcoded-navbar.menu-light.navbar-collier.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-collier.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  background-color: #25408f;
  border-color: #25408f;
}

nav.pcoded-navbar.menu-light.navbar-jll.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-jll.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  background-color: #E30613;
  border-color: #E30613;
}

nav.pcoded-navbar.menu-light.navbar-pepsico.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-pepsico.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  background-color: #004c97;
  border-color: #004c97;
}

nav.pcoded-navbar.menu-light.navbar-cushman.brand-default+header.navbar.pcoded-header+div.pcoded-main-container .nav-item.nav-link.active,
nav.pcoded-navbar.menu-light.navbar-cushman.brand-default+header.navbar.pcoded-header+div.pcoded-main-container button.btn-primary {
  background-color: #e4002b;
  border-color: #e4002b;
}

.orangeColor {
  position: relative;
}
.blueColor{
  position: relative;

}
.greenColor{
  position: relative;

}
.yellowColor{
  position: relative;

}



i.feather.orange {
  color: orange;
}
i.feather.green {
  color: green;
}
i.feather.yellow {
  color: yellow;
}
i.feather.blue {
  color: blue;
}
i.feather.black {
  color: black;
}

.iconWithComments span {
  margin-right: 5px;
}

// admin layout css
// .pcoded-content.orangeColorLeftBorder {
//   border-left: 5px solid orange;
// }
// .pcoded-content.blueColorLeftBorder {
//   border-left: 5px solid blue;
// }
// .pcoded-content.greenColorLeftBorder {
//   border-left: 5px solid green;
// }

// .pcoded-content.blackColorLeftBorder {
//   border-left: 5px solid black;
// }

// .pcoded-content.yellowColorColorLeftBorder{
//   border-left: 5px solid yellow;

// }

// .blueColorLeftBorder:before {
//   content: "";
//   width: 6px;
//   height: 120%;
//   // background-color: blue;
//   position: absolute;
//   display: block;
//   top: 0px;
//   left: 0px;
// }

// .yellowColorColorLeftBorder:before {
//   content: "";
//   width: 6px;
//   // background-color: yellow;
//   position: absolute;
//   display: block;
//   top: 0px;
//   left: 0px;
//   height: 120%;

// }

// .greenColorLeftBorder:before {
//   content: "";
//   width: 6px;
//   // background-color: green;
//   position: absolute;
//   display: block;
//   top: 0px;
//   height: 120%;
//   left: 0px;
// }

// .blackColorLeftBorder:before {
//   content: "";
//   width: 6px;
//   background-color: black;
//   position: absolute;
//   display: block;
//   top: 0px;
//   left: 0px;
//   top: 0px;
//   height: 120%;
// }


// .orangeColorLeftBorder:before {
//   content: "";
//   width: 6px;
//   background-color: orange;
//   position: absolute;
//   display: block;
//   top: 0px;
//   left: 0px;
//   height: 120%;

// }




// .listPage{
//    position: absolute;
//     display: block;
//     top: 0px;
//     left: 0px;
//     top: 0px;
//     height: 120%;
// }

li.nav-item.pcoded-trigger:before {
  display: none;
}


.pcoded-content.pt-0:before {
  display: none;
}
// li.nav-item.pcoded-trigger.orangeColorLeftBorder a {
//   border-left: 5px solid orange;
// }
// li.nav-item.pcoded-trigger.blackColorLeftBorder a {
//   border-left: 5px solid black;
// }
// li.nav-item.pcoded-trigger.blueColorLeftBorder a {
//   border-left: 5px solid blue;
// }
// li.nav-item.pcoded-trigger.greenColorLeftBorder a {
//   border-left: 5px solid green;
// }

// li.nav-item.pcoded-trigger.yellowColorColorLeftBorder a {
//   border-left: 5px solid yellow;
// }


// li.keydates a:hover {
//   border-left: 5px solid green;
// }
// li.buildings a:hover {
//   border-left: 5px solid orange;
// }
// li.budgettracking a:hover {
//   border-left: 5px solid black;
// }
// li.leases a:hover {
//   border-left: 5px solid blue;
// }
// li.projects a:hover {
//   border-left: 5px solid yellow;
// }

// li.nav-item.pcoded-trigger.buildings a {
//   border-left: 5px solid orange;
// }
// li.nav-item.pcoded-trigger.budgettracking a {
//   border-left: 5px solid black;
// }
// li.nav-item.pcoded-trigger.leases a {
//   border-left: 5px solid blue;
// }
// li.nav-item.pcoded-trigger.keydates.active {
//   border-left: 5px solid green;
// }
// li.nav-item.pcoded-trigger.projects a {
//   border-left: 5px solid yellow;
// }

// li.nav-item.pcoded-trigger.buildings.activeParam a {
//   border-left: 5px solid orange;
// }
// li.nav-item.pcoded-trigger.budgettracking a {
//   border-left: 5px solid black;
// }
// li.nav-item.pcoded-trigger.leases a {
//   border-left: 5px solid blue;
// }
// li.nav-item.pcoded-trigger.keydates a {
//   border-left: 5px solid green;
// }
// li.nav-item.pcoded-trigger.projects a {
//   border-left: 5px solid yellow;
// }


// .keydates .nav-link.active {
//   border-left: 5px solid green;
// }


.pcoded-navbar .pcoded-inner-navbar > li.active:after, .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger:after{
  display: none !important
}



// .kpiFilter {
//   display: flex;
//   justify-content: space-between;
// }
span.mr-3.homeDropTittle {
  font-size: 15px;
  font-weight: 500;
  color: black;
}
.hompageFilter div {
  width: 70%;
  margin-bottom: 10px;
}
.hompageFilter div {
  width: 100%;
  margin-bottom: 10px;
}
span.commentRecordHeading {
  color: #4c4a4a;
}

// .card .card-block, .card .card-body

.pb-0.commentBlock.card-body {
  padding: 0px;
}
li.media.mt-2.flex-column:before {
  display: none;
}
.commentBlock li.media.mt-2.flex-column {
  margin-top: 0px !important;
}
li.media.mt-2.flex-column.orangeColor {
  border-left: 5px solid orange;
}
li.media.mt-2.flex-column.blueColor {
  border-left: 5px solid blue;
}
li.media.mt-2.flex-column.greenColor {
  border-left: 5px solid green;
}
li.media.mt-2.flex-column.yellowColor {
  border-left: 5px solid yellow;
}
li.media.mt-2.flex-column.blackColor {
  border-left: 5px solid black;
}

hr.w-100.mb-2.mt-0.hrFix {
  display: none;
}
.commentBlock li.media.mt-2.flex-column {
  margin-top: 0px !important;
  border-bottom: 1px solid #e0e0e0;
}
.commentBlock li.media.mt-2.flex-column {
  margin-top: 0px !important;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 10px;
}




.width200{
  width: 200px;
}