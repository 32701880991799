@import '~rc-slider/assets/index.css';
@import '~rc-tooltip/assets/bootstrap.css';

.pc-range-slider {
  &.rc-slider {
    &.rc-slider-disabled {
      background-color: transparent;
      .rc-slider-handle {
        background: #aaa;
        border-color: #aaa;
      }
    }

    &.square-handler {
      .rc-slider-handle {
        border-radius: 0;
      }
    }

    &.triangle-handler {
      .rc-slider-handle {
        border-width: 0 15px 15px 15px;
        width: 0;
        height: 0;
        background: transparent none;
        border-color: transparent;
        border-bottom-color: #29b8f9;
        border-radius: 0;
      }
    }

    .rc-slider-step,
    .rc-slider-rail,
    .rc-slider-track {
      height: 8px;
    }

    .rc-slider-handle {
      width: 16px;
      height: 16px;
      background: #29b8f9;
      border-color: #29b8f9;
    }

    .rc-slider-dot {
      width: 12px;
      height: 12px;
    }
  }

  &.rc-slider-vertical {
    height: 300px;
    margin-left: 10px;
    .rc-slider-rail {
      height: 100%;
    }
    &.triangle-handler {
      .rc-slider-handle {
        left: -3px;
      }
    }
  }
}
