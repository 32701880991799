/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
$i: 0;

@while $i<=50 {
  .p {
    &-#{$i} {
      padding: #{$i}px;
    }

    &-t-#{$i} {
      padding-top: #{$i}px;
    }

    &-b-#{$i} {
      padding-bottom: #{$i}px;
    }

    &-l-#{$i} {
      padding-left: #{$i}px;
    }

    &-r-#{$i} {
      padding-right: #{$i}px;
    }
  }

  .m {
    &-#{$i} {
      margin: #{$i}px;
    }

    &-t-#{$i} {
      margin-top: #{$i}px;
    }

    &-b-#{$i} {
      margin-bottom: #{$i}px;
    }

    &-l-#{$i} {
      margin-left: #{$i}px;
    }

    &-r-#{$i} {
      margin-right: #{$i}px;
    }
  }

  $i: $i + 5;
}

/*====== Padding , Margin css ends ======*/

/*====== text-color, background color css starts ======*/
@each $value in $color-name {
  $i: index($color-name, $value);

  .bg-#{$value} {
    background: nth($color-color, $i);
  }

  .text-#{$value} {
    color: nth($color-color, $i);
  }
}

/*====== text-color css ends ======*/
/*====== Card top border css starts ======*/

@each $value in $color-name {
  $i: index($color-name, $value);

  .card-border-#{$value} {
    border-top: 4px solid nth($color-color, $i);
  }
}

/*====== Card top border ends ======*/
/*====== Font-size css starts ======*/

/*====== text-color css ends ======*/
/*====== Card top border css starts
custom-color-23b7e5
======*/

@each $value in $color-codes {
  $i: index($color-codes, $value);
  $valueColor: nth($color-codes, $i);

  .custom-color-#{$valueColor} {
    background-color: ##{$valueColor};
  }

  .pcoded-header.header-#{$valueColor} {
    background-color: ##{$valueColor} !important;
    color: white;
  }

  .budget-color-#{$valueColor}:before {
    content: "";
    width: 6px;
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    height: 120%;
    background-color: ##{$valueColor} !important;
  }

  .building-color-#{$valueColor}:before {
    content: "";
    width: 6px;
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    height: 120%;
    background-color: ##{$valueColor} !important;
  }

  .project-color-#{$valueColor}:before {
    content: "";
    width: 6px;
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    height: 120%;
    background-color: ##{$valueColor} !important;
  }

  .lease-color-#{$valueColor}:before {
    content: "";
    width: 6px;
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    height: 120%;
    background-color: ##{$valueColor} !important;
  }

  .keyDate-color-#{$valueColor}:before {
    content: "";
    width: 6px;
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    height: 120%;
    background-color: ##{$valueColor} !important;
  }

  .navbar-#{$valueColor} {
    background-color: ##{$valueColor} !important;
  }

  .brand-#{$valueColor} {
    background-color: ##{$valueColor} !important;
  }

  .button-color-#{$valueColor} button.btn {
    background-color: ##{$valueColor} !important;
    border: 1px solid ##{$valueColor};
  }

  .button-color-#{$valueColor} button.btn.outlineBtn {
    background-color: white !important;
    color: black;
    border: 1px solid ##{$valueColor};
  }

  .button-color-#{$valueColor} .menu-styler .style-toggler>a:before {
    color: ##{$valueColor} !important;
  }

  .tab-color-#{$valueColor} a.active {
    background-color: ##{$valueColor} !important;
    color: white;
  }

  .project-color-#{$valueColor} a.active {
    background-color: ##{$valueColor} !important;
    color: white;
  }

  .building-color-#{$valueColor} a.active {
    background-color: ##{$valueColor} !important;
    color: white;
  }

  .keyDate-color-#{$valueColor} a.active {
    background-color: ##{$valueColor} !important;
    color: white;
  }

  .lease-color-#{$valueColor} a.active {
    background-color: ##{$valueColor} !important;
    color: white;
  }

  .budget-color-#{$valueColor} a.active {
    background-color: ##{$valueColor} !important;
    color: white;
  }

  .tab-color-#{$valueColor} span.tabMenuSingle.active {
    background-color: ##{$valueColor} !important;
    color: white;
  }
}

/*====== Card top border ends ======*/
/*====== Font-size css starts ======*/

$i: 10;

@while $i<=80 {
  .f-#{$i} {
    font-size: #{$i}px;
  }

  $i: $i + 2;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
$i: 100;

@while $i<=900 {
  .f-w-#{$i} {
    font-weight: #{$i};
  }

  $i: $i + 100;
}

/*====== Font-weight css ends ======*/
/*====== width, Height css starts ======*/
$i: 20;

@while $i<=150 {
  .wid-#{$i} {
    width: #{$i}px;
  }

  .hei-#{$i} {
    height: #{$i}px;
  }

  $i: $i + 5;
}

/*====== width, Height css ends ======*/

.buttonBlinkRed {
  animation: glowingRed 1300ms infinite;
}

.buttonBlinkBlue {
  animation: glowingBlue 1300ms infinite;
}

.buttonBlinkYellow {
  animation: glowingYellow 1300ms infinite;
}

.buttonBlinkGreen {
  animation: glowingGreen 1300ms infinite;
}

@keyframes glowingRed {
  0% {
    background-color: #b2004d;
    box-shadow: 0 0 3px #b2004d;
  }

  50% {
    background-color: #ed2135;
    box-shadow: 0 0 15px #ed2135;
  }

  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowingBlue {
  0% {
    background-color: #b28300;
    box-shadow: 0 0 3px #b27700;
  }

  50% {
    background-color: #ed9321;
    /* Updated to orange color */
    box-shadow: 0 0 15px #f7bc31;
    /* Updated to orange color */
  }

  100% {
    background-color: #b28500;
    box-shadow: 0 0 3px #b28300;
  }
}

@keyframes glowingYellow {
  0% {
    background-color: #acb200;
    box-shadow: 0 0 3px #acb200;
  }

  50% {
    background-color: #d2ed21;
    box-shadow: 0 0 15px #dced21;
  }

  100% {
    background-color: #97b200;
    box-shadow: 0 0 3px #80b200;
  }
}

@keyframes glowingGreen {
  0% {
    background-color: #00b224;
    box-shadow: 0 0 3px #00b227;
  }

  50% {
    background-color: #21ed40;
    box-shadow: 0 0 15px #21ed46;
  }

  100% {
    background-color: #00b238;
    box-shadow: 0 0 3px #00b238;
  }
}

button.buttonBlinkRed.btn-primary.btn-sm {
  border: red !important;
  background-color: red !important;
}

button.buttonBlinkYellow.btn-primary.btn-sm {
  border: #fddd5c !important;
  background-color: #fddd5c !important;
  color: black !important;
}

button.buttonBlinkGreen.btn-primary.btn-sm {
  border: #029830 !important;
  background-color: #029830 !important;
}

button.buttonBlinkBlue.btn-primary.btn-sm {
  border: #ffb449 !important;
  background-color: #ffb449 !important;
  color: black !important;
}