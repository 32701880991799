.breadcrumb-item a {
    font-size: 11px;
}

.topIconList {
    display: flex;
    justify-content: center;
    align-items: center;
}



.buildingGridKeyDate {
    display: grid;
    grid-template-columns: minmax(275px, 275px) minmax(70px, 70px) minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px);
}

.LeaseGridKeyDate {
    display: grid;
    grid-template-columns: minmax(230px, 230px) minmax(70px, 70px) minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px);
}

.keyDateInfoGrid {
    display: grid;
    grid-template-columns: minmax(137px, 150px) minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px) minmax(99px, 99px);
    grid-gap: 10px;
}

.projectGridKeyDate {
    display: grid;
    grid-template-columns: minmax(150px, 150px) minmax(120px, 120px) minmax(100px, 100px) minmax(100px, 100px) minmax(120px, 120px);
}

.stageAddBgColor.text-yellow {
    color: #1e1e1d !important;
    background: #ffffe2 !important;
}

.addStageGridDataTable {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}

.firstCardSticky.card {
    position: sticky;
    left: 80px;
    top: 200px;
    z-index: 999;
}

.btnIconSurvey {
    right: -23px;
    top: 0px;
}

.landlordSurveyGrid {
    grid-gap: 25px;
}

.k-loading-mask {
    z-index: 9999;
}

.chatIconTop {
    right: 118px;
}

.detailChat {
    top: 19px;
}

table.strategicPlanDetailTable,
table.strategicPlanDetailTable td,
table.strategicPlanDetailTable th {
    padding-left: 15px;
    padding-right: 15px;
}

.compositeMainGrid {
    display: grid;
    grid-template-columns: 4fr 2fr 1.5fr;
    gap: 5px;
}

.strategicPlanDetailTable th:nth-child(2) {
    min-width: 300px;
}

.siteSelectionListView button.ml-1.k-button.k-button-md.k-rounded-md {
    font-size: 11px;
    font-weight: 600;
}

.stageAddBgColor {
    background: #2f4f4f !important;
}

.topIcon i {
    font-size: 18px;
}

.topIcon li {
    margin-right: 22px !important;
}

.chatIconTop {
    right: 156px !important;
    font-size: 17px;
    top: 15px;
}

.chatIconTop i {
    color: #3f4d67;
}

li.newLi.settingN {
    margin-right: 0 !important;
}

span.chatIconTop.mr-3.cp.strategyDetail {
    top: 7px;
}

span.chatIconTop.mr-3.cp.detailChat {
    top: 7px;
}

.iconsDFix button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator {
    position: absolute;
    right: 30px;
    top: 0px;
}

.iconsDFix button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator {
    position: absolute;
    right: 0;
}

.siteSelectionListView button.btn {
    // background-color: #2F4F4F !important;
    border: 1px solid #e7e7e7;
}

// ul#nav-ps-next li.keydatemanagement {
//     border-bottom: 1px solid #c7c7c7;
// }

.mh138 img {
    max-height: 120px !important;
    object-fit: contain;
    padding: 5px 5px;
}

.topIcon li:last-child {
    margin-right: 0px !important;
}

.compositeMainGrid.buildingDetail {
    grid-template-columns: 1.5fr 4fr 2fr;
}

.detailPageHeader {
    padding: 5px 25px !important;
}

.top8 {
    top: 8px !important;
}

ul#nav-ps-next li:first-child {
    margin-top: 50px;
}

.monthlyTables {
    display: flex;
}

.monthlyTableParent {
    min-width: 200px;
}

.monthlyTitle {
    font-size: 16px;
    font-weight: 700;
    color: #333;
}

.optimizationTableDetail.monthlyTable {
    display: flex;
    max-width: 100% !important;
}

.monthlyTable th {
    padding: 0px 15px;
}

.monthlyTable th:nth-child(1) {
    min-width: 60px;
}

.monthlyTable th:nth-child(2) {
    min-width: 60px;
    // display: inline-flex;
}

.monthlyTable th:nth-child(2) {
    min-width: auto;
}

.optimizationTableDetail th:nth-child(2) {
    width: auto;
}

.monthlyTable th:nth-child(1) {
    min-width: auto;
}


.optimizationTableDetail th:nth-child(1) {
    min-width: auto;
    white-space: nowrap;
}

.sameLine {
    display: inline-flex;
    white-space: nowrap;
}

.breadcrumb-item a {
    color: black !important;
    cursor: pointer;
}

.requirementCardActive {
    border: 1px solid black;
}

.customAlertHeader {
    margin-top: 25px !important;
    margin-bottom: 0 !important;
}

.customAlertHeader h5 {
    font-size: 14px;
    margin-bottom: 0;
}

.opBg1 {
    background: #FAF3F0;
}

.opBg2 {
    background: #e9ede9;
}

.opBg3 {
    background: #f1ecec;
}

.opBg4 {
    background: #efe9f5;
}

.opBg0 {
    background: #ecf5f7;
}

.opBg9 {
    background: #faf1fa;
}


.strategyProgressBar {
    margin-top: -15px;
}

.approvalTabGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 4fr;
    gap: 10px;
}

.selectedPossiblity {
    box-shadow: 0.2rem 0.2rem #ccc, -0.2rem -0.2rem rgb(240, 240, 240);
}

header.navbar.pcoded-header.navbar-expand-lg {
    display: none !important;
}

.fileUploadIcon {
    font-size: 35px;
    color: #3c3c3c;
}

.fontIconZoom {
    color: #393939;
    font-size: 22px;
    margin-right: 5px;
}

.settingGridLayout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 55px;
}

.settingGridLayout h4.mainTiSw.cr {
    color: #3f3f3f;
    display: flex;
    align-items: center;
}

button.buttonBlinkRed.ml-2.k-button.k-button-md.k-rounded-md {
    color: white;
    border: none;
}

button.buttonBlinkGreen.ml-2.k-button.k-button-md.k-rounded-md {
    color: white;
    border: none;
}

.colorPickerGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

div.colorBox {
    height: 30px;
    width: 40px;
}

.marketSurveyIcons {
    position: absolute;
    right: 160px;
    top: -6.5px;
}

button.buttonBlinkRed.btn.cPadding.btn-secondary.m-0.px-3 {
    background: red !important;
    border: red !important;
}

button.buttonBlinkGreen.btn.cPadding.btn-secondary.m-0.px-3 {
    border: #029830 !important;
    background-color: #029830 !important;
}

.projectDetailGoal .showExportBtn {
    position: absolute;
    right: 25px;
    top: 12px;
}

.projectListGoal .showExportBtn {
    position: absolute;
    right: 15px;
    top: 13px;
}

.gridCommentText a {
    background: #828d23;
    padding: 0 9px;
    border-radius: 6px;
    color: white !important;
    cursor: none;
    pointer-events: none;
}

.csatRatingCard .mb-0.card {
    display: flex;
    height: 100%;
}

.csatRatingCard .p-3.card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.csatRatingCard h3.f-w-300.d-flex.align-items-center.m-b-0 {
    white-space: nowrap;
}