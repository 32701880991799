.react-modal {
  z-index: 1030 !important;
  top: 0;
  left: 0;

  .react-modal-overlay {
    background-color: #000;
    opacity: 0.55;
    &.animate-modal-overlay-enter-done {
      opacity: 0.55;
    }
  }

  .react-modal-body {
    width: 50%;
    min-width: 310px;
    max-width: 640px;

    .react-modal-body-close {
      z-index: 1;
      top: 22px;
      right: 22px;
      &:before,
      &:after {
        background: #fff;
      }
    }

    .card {
      box-shadow: none;
      margin-bottom: 0;
      .card-header {
        color: #fff;
        &:first-child {
          border-radius: 0;
        }
      }
      .card-body {
        padding-bottom: 0;
      }
      .card-footer {
        border-top: none;
        padding: 10px 0;
      }
    }
  }
}
