.show-label {
  .react-dual-listbox {
    margin-top: 30px;
    .rdl-control-label {
      clip: auto;
      font-weight: 600;
      top: 85px;
      padding: 6px;
      color: #333;
    }
  }
}
